import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { ComponentPropsWithoutRef, useRef, useEffect } from "react";

type VideoProps = {
  focused: boolean;
  containerStyle?: ThemeUIStyleObject;
} & Omit<ComponentPropsWithoutRef<"video">, "onClick" | "tabIndex" | "aria-label">;

export const DojoIslandsActivityCardVideo = ({
  src,
  focused: focused,
  preload = "auto",
  poster,
  containerStyle,
  ...props
}: VideoProps): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    if (focused) {
      videoRef.current.play()?.catch(() => {
        // ignore
      });
    } else {
      videoRef.current.pause();
      videoRef.current.load();
    }
  }, [focused]);
  return (
    <div sx={{ ...styles.container, ...containerStyle }}>
      <video
        crossOrigin="anonymous"
        ref={videoRef}
        {...props}
        src={src}
        preload={preload}
        muted={true}
        loop={true}
        sx={styles.video}
      >
        <p>Your browser does not support video. Please update your browser.</p>
      </video>
      {poster && <img src={poster} sx={{ ...styles.poster, opacity: focused ? 0.001 : 1 }} alt="" />}
    </div>
  );
};

const styles: Record<string, ThemeUIStyleObject> = {
  container: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%", // 16:9 aspect ratio
  },
  video: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  poster: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    transition: "opacity 0.3s ease-out",
  },
};
