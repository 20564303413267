import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
/* eslint-disable no-console */
import * as logClient from "@classdojo/log-client";
import { logEvent } from "@classdojo/log-client";
import { useIconSrc } from "@classdojo/web/utils/iconSet";
import callApi from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { APIRequestBody, APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { useAllClassroomFetcher } from "@web-monorepo/shared/classroom";
import { useClassMessageThreadsFetcher } from "@web-monorepo/shared/messaging/hooks";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import {
  NOOP,
  makeApiMutation,
  makeCollectionQuery,
  makeMemberQuery,
  makeMutation,
} from "@web-monorepo/shared/reactQuery";
import { HAS_DISMISSED_NO_CONNECTIONS_MODAL } from "app/data/userConfigKeys";
import { useBehaviorsFetcher } from "app/pods/behavior";
import { useSchoolClassesFetcher } from "app/pods/classDirectory";
import { useMonsterverseConfigFetcher } from "app/pods/dojoIslands";
import { useJoinClassRequestsFetcher } from "app/pods/joinClassRequests";
import { useNotificationsFetcher } from "app/pods/notification";
import { getSchoolTeachers, useSchoolFetcher } from "app/pods/school";
import { useSchoolStudentsFetcher } from "app/pods/schoolStudents";
import { useClassStoryPostsFetcher, useStoryPostFetcher } from "app/pods/story";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import { useFetchedTeacher, useFetchedTeacherSelectedSchool } from "app/pods/teacher";
import { getMetadataValue, useUserConfigFetcher } from "app/pods/userConfig";
import { isActive } from "app/utils/classroom";
import env from "app/utils/env";
import { useLocalStorage } from "@web-monorepo/safe-browser-storage";
import { useSelector } from "app/utils/reduxHooks";
import { isVerified } from "app/utils/school";
import { useCallback, useMemo } from "react";
import { titleLast } from "@classdojo/web/utils/name";
import assert from "assert";
import { useSchoolwidePointsDashboardFetcher } from "#/src/pages/(sidebar)/schools/[schoolId]/points/_api/fetchers";
import { useMonsterverseActivitiesFetcher } from "app/pods/dojoIslands/componentsV3/_api/fetchers";
import { useShouldShowSchoolData } from "#/src/lib/useShouldShowSchoolData";

export const isFirstClassroom = (classrooms: Classroom[]) => classrooms.every((c) => c.demo);

export type Collaborator = NonNullable<Classroom["collaborators"]>[number];
export type ClassroomPendingCollaborator = Extract<Collaborator, { sharingStatus: "pending" }>;
export type ClassroomRequestedCollaborator = Extract<Collaborator, { sharingStatus: "requested" }>;
export type ClassroomCollaborator = Extract<Collaborator, { sharingStatus: "owner" | "accepted" }>;

type State = {
  tempPreferences: Record<string, ClassroomPreferences>;
  editClassModalPane: null;
};

const initialState: State = {
  tempPreferences: {},
  editClassModalPane: null,
};

const UPDATE_TEMP_PREFERENCES = createAction("classroom/update-temp-preferences");

const RESET_TEMP_PREFERENCES = createAction("classroom/reset-temp-preferences");

const SET_EDIT_CLASS_MODAL_PANE = createAction("classroom/edit-class-view");

// represents May 4, 2016, the date after which new classrooms should default to not showing
// notifications for negative points.
// This was reversed Sept 27, 2016
const NO_NEGATIVE_BANNER_START = "57299df00000000000000000";
const NO_NEGATIVE_BANNER_END = "57ea18f00000000000000000";

export const STATE_KEY = "classroom";

type ClassroomSlice = {
  [STATE_KEY]: State;
};

export type Classroom = APIResponse<"/api/dojoClass/{id}", "get">;
export type ClassroomPreferences = NonNullable<Classroom["preferences"]>;
export type PointsSharing = NonNullable<ClassroomPreferences["pointsSharing"]>;

export const useClassroomFetcher = makeMemberQuery({
  fetcherName: "classroom",
  path: "/api/dojoClass/{id}",
  query: { withRequestedCollaborators: "true" },
  onSuccess: (_data, params) => {
    useBehaviorsFetcher.invalidateQueries({ classId: params.id });
    useStudentsFetcher.invalidateQueries({ classId: params.id });
  },
  dontThrowOnStatusCodes: [403],
});

export const usePersonalClassesFetcher = makeCollectionQuery({
  fetcherName: "personalClasses",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/classes",
});

export const useClassesFetcher = makeCollectionQuery({
  fetcherName: "classes",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/classes",
  query: {
    withCoTeacher: "true",
    withInactive: "true",
    withPending: "true",
    withRequested: "true",
    withRequestedCollaborators: "true",
  },
  queryParams: ["withPersonal", "withDemo", "withArchived"],
});

export type UpdateClassroomParams = Partial<APIRequestBody<"/api/dojoClass/{id}", "put">> & { schoolId?: string };
type UpdateClassroomResponse = APIResponse<"/api/dojoClass/{id}", "put">;

export const useUpdateClassroomOperation = makeMutation<UpdateClassroomParams, UpdateClassroomResponse>({
  name: "updateClassroom",
  fn: async (params) => {
    const { body } = await callApi({
      method: "PUT",
      path: `/api/dojoClass/${params._id}`,
      body: params,
    });

    return body;
  },
  onSuccess: (_data, params) => {
    if (params._id) {
      useClassroomFetcher.invalidateQueries({ id: params._id });
    }
    if (params.schoolId) {
      useSchoolClassesFetcher.invalidateQueries({ schoolId: params.schoolId });
      useClassesFetcher.invalidateQueries({ schoolId: params.schoolId });
    } else {
      useClassesFetcher.invalidateQueries({ schoolId: "personal_classes" });
    }
    useAllClassroomFetcher.invalidateQueries();
  },
  onMutate: (params) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom) => classroom._id === params.classroomId);
      if (!classroomToUpdate) return;

      Object.assign(classroomToUpdate, params);
    });
  },
});

export type ArchiveClassroomParams = {
  classroomId: string;
  schoolId?: string;
  archived: boolean;
};
type ArchiveClassroomResponse = APIResponse<"/api/dojoClass/{id}/archive", "put">;

export const useArchiveClassroomOperation = makeMutation<ArchiveClassroomParams, ArchiveClassroomResponse>({
  name: "archiveClassroom",
  fn: async (params) => {
    const { body } = await callApi({
      method: "PUT",
      path: `/api/dojoClass/${params.classroomId}/archive`,
      body: { archived: params.archived },
    });

    return body;
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.setQueriesData((draft) => {
      if (draft._id === params.classroomId) {
        draft.archived = !!params.archived;
      }
    });
    useClassMessageThreadsFetcher.invalidateQueries();
    useAllClassroomFetcher.invalidateQueries();
    if (params.schoolId) {
      useSchoolClassesFetcher.invalidateQueries({ schoolId: params.schoolId });
      useClassesFetcher.invalidateQueries({ schoolId: params.schoolId });
    } else {
      useClassesFetcher.invalidateQueries({ schoolId: "personal_classes" });
    }
  },
});

export const useUpdateClassSchoolIdOperation = makeApiMutation({
  name: "updateClassSchoolId",
  path: "/api/dojoClass/{classId}/school/{schoolId}",
  method: "put",
  onSuccess: (_data, { path }) => {
    useAllClassroomFetcher.setQueriesData((draft) => {
      const matchingClassroom = draft.find((classroom) => classroom._id === path.classId);
      if (matchingClassroom) {
        matchingClassroom.schoolId = path.schoolId;
      }
    });
    useClassroomFetcher.setQueriesData((draft) => {
      if (draft._id === path.classId) {
        draft.schoolId = path.schoolId;
      }
    });
  },
});

// TODO: TSM we 'rename' to classroomId to avoid modifying the runtime. Once all is typed we can rename and use the same name as in API
type TransferClassroomOwnershipParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/owner", "post">["path"],
  "classId"
> &
  Partial<APIRequestBody<"/api/dojoClass/{classId}/owner", "post">> & {
    classroomId: string;
    teacherId: string;
    teacherEmail?: string;
  };
type TransferClassroomOwnershipResponse = APIResponse<"/api/dojoClass/{classId}/owner", "post">;

export const useTransferClassroomOwnershipOperation = makeMutation<
  TransferClassroomOwnershipParams,
  TransferClassroomOwnershipResponse
>({
  name: "transferClassroomOwnership",
  fn: async ({ classroomId, teacherId, teacherEmail }) => {
    const { body } = await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/owner`,
      body: {
        ownerId: teacherId,
        ownerEmail: teacherEmail,
      },
    });

    return body;
  },
  onMutate: (params) => {
    useSchoolClassesFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom: Classroom) => classroom._id === params.classroomId);
      if (classroomToUpdate) {
        classroomToUpdate.teacher = params.ownerId ?? params.teacherId;
      }
    });
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.invalidateQueries({ id: params.classroomId });
  },
});

// TODO: TSM we 'rename' to classroomId to avoid modifying the runtime. Once all is typed we can rename and use the same name as in API
type ApproveJoinRequestClassroomParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/teacher/{emailAddress}/approve", "post">["path"],
  "classId"
> & { classroomId: string };
type ApproveJoinRequestClassroomResponse = APIResponse<
  "/api/dojoClass/{classId}/teacher/{emailAddress}/approve",
  "post"
>;

export const useApproveJoinRequestClassroomOperation = makeMutation<
  ApproveJoinRequestClassroomParams,
  ApproveJoinRequestClassroomResponse
>({
  name: "approveJoinRequest",
  fn: async ({ classroomId, emailAddress }) => {
    const { body } = await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/teacher/${emailAddress}/approve`,
    });

    return body;
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.invalidateQueries({ id: params.classroomId });
    useJoinClassRequestsFetcher.invalidateQueries();
  },
});

// TODO: TSM we 'rename' to classroomId to avoid modifying the runtime. Once all is typed we can rename and use the same name as in API
type RemoveClassroomCollaboratorParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/teacher/{emailAddress}", "delete">["path"],
  "classId"
> & { classroomId: string };
type RemoveClassroomCollaboratorResponse = APIResponse<"/api/dojoClass/{classId}/teacher/{emailAddress}", "delete">;

export const useRemoveClassroomCollaboratorOperation = makeMutation<
  RemoveClassroomCollaboratorParams,
  RemoveClassroomCollaboratorResponse
>({
  name: "removeClassroomCollaborator",
  fn: async ({ classroomId, emailAddress }) => {
    try {
      await callApi({
        method: "DELETE",
        path: `/api/dojoClass/${classroomId}/teacher/${emailAddress}`,
      });
    } catch (ex: any) {
      // API will 404 if the collaborator is no longer in the class
      if (ex?.response?.status === 404) {
        return;
      }
      throw ex;
    }
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.invalidateQueries({ id: params.classroomId });
    useJoinClassRequestsFetcher.invalidateQueries();
    useSchoolClassesFetcher.invalidateQueries();
  },
});

type UpdateClassroomStoryCommentParams = APIRequestBody<"/api/dojoClass/{classId}/storyCommentSettings", "post"> & {
  classroomId: string;
};
type UpdateClassroomStoryCommentResponse = APIResponse<"/api/dojoClass/{classId}/storyCommentSettings", "post">;

export const useUpdateClassroomStoryCommentOperation = makeMutation<
  UpdateClassroomStoryCommentParams,
  UpdateClassroomStoryCommentResponse
>({
  name: "updateClassroomStoryComment",
  fn: async ({ classroomId, storyCommentsDisabled, studentStoryCommentsEnabled, studentAccountConsent }) => {
    const { body } = await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/storyCommentSettings`,
      body: {
        storyCommentsDisabled,
        studentStoryCommentsEnabled,
        studentAccountConsent,
      },
    });

    return body;
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.invalidateQueries();
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.classroomId });
    useStoryPostFetcher.invalidateQueries();
  },
});

type UpdateClassroomMessagingSettingsParams = APIRequestBody<"/api/dojoClass/{classId}/message_settings", "put"> & {
  classroomId: string;
};
type UpdateClassroomMessagingSettingsResponse = APIResponse<"/api/dojoClass/{classId}/message_settings", "put">;

export const useUpdateClassroomMessagingSettingsOperation = makeMutation<
  UpdateClassroomMessagingSettingsParams,
  UpdateClassroomMessagingSettingsResponse
>({
  name: "updateClassroomMessagingSettings",
  fn: async ({ classroomId, classStarterChatEnabled, familyMessagingEnabled }) => {
    const { body } = await callApi({
      method: "PUT",
      path: `/api/dojoClass/${classroomId}/message_settings`,
      body: {
        classStarterChatEnabled,
        familyMessagingEnabled,
      },
    });

    return body;
  },
  onSuccess: () => {
    useClassroomFetcher.invalidateQueries();
    useClassMessageThreadsFetcher.invalidateQueries();
  },
});

type UpdateClassroomAutoHatchDateParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/autoHatchSettings", "post">["path"],
  "classId"
> &
  APIRequestBody<"/api/dojoClass/{classId}/autoHatchSettings", "post"> & { classroomId: string };
type UpdateClassroomAutoHatchDateResponse = APIResponse<"/api/dojoClass/{classId}/autoHatchSettings", "post">;

export const useUpdateClassroomAutoHatchDateOperation = makeMutation<
  UpdateClassroomAutoHatchDateParams,
  UpdateClassroomAutoHatchDateResponse
>({
  name: "updateClassroomAutoHatchDate",
  fn: async ({ classroomId, autoHatchAt }) => {
    const { body } = await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/autoHatchSettings`,
      body: {
        autoHatchAt,
      },
    });

    return body;
  },
  onSuccess: () => {
    useClassroomFetcher.invalidateQueries();
  },
});

export const useClassroomDojoIslandsSettingsFetcher = makeMemberQuery({
  fetcherName: "classroomDojoIslandsSettings",
  path: "/api/dojoClass/{classId}/dojoIslandsSettings",
});

export type UpdateClassroomDojoIslandsSettingsParams = Partial<
  APIRequestBody<"/api/dojoClass/{classId}/dojoIslandsSettings", "put">
> & { classroomId: string };
type UpdateClassroomDojoIslandsSettingsResponse = APIResponse<"/api/dojoClass/{classId}/dojoIslandsSettings", "put">;

export const useUpdateClassroomDojoIslandsSettingsOperation = makeMutation<
  UpdateClassroomDojoIslandsSettingsParams,
  UpdateClassroomDojoIslandsSettingsResponse
>({
  name: "updateClassroomDojoIslandsSettings",
  fn: async ({
    classroomId,
    allowDuringClassHours,
    teacherOptOut,
    allowClassIslands,
    enableSpookySeason,
    enableFallSeason,
    enableWinterSeason,
  }) => {
    await callApi({
      method: "PUT",
      path: `/api/dojoClass/${classroomId}/dojoIslandsSettings`,
      body: {
        allowDuringClassHours,
        teacherOptOut,
        allowClassIslands,
        enableSpookySeason,
        enableFallSeason,
        enableWinterSeason,
      },
    });
  },
  onMutate: ({ classroomId, enableSpookySeason, enableFallSeason, enableWinterSeason }) => {
    if (enableSpookySeason != null) {
      useMonsterverseConfigFetcher.setQueriesData(
        (cachedData) => {
          if (cachedData.type === "teacher" && cachedData.seasonalContent.spookySeason) {
            cachedData.seasonalContent.spookySeason.showContent = enableSpookySeason;
          }
        },
        { classId: classroomId },
      );
    }
    if (enableFallSeason != null) {
      useMonsterverseConfigFetcher.setQueriesData(
        (cachedData) => {
          if (cachedData.type === "teacher" && cachedData.seasonalContent.fallSeason) {
            cachedData.seasonalContent.fallSeason.showContent = enableFallSeason;
          }
        },
        { classId: classroomId },
      );
    }
    if (enableWinterSeason != null) {
      useMonsterverseConfigFetcher.setQueriesData(
        (cachedData) => {
          if (cachedData.type === "teacher" && cachedData.seasonalContent.winterSeason) {
            cachedData.seasonalContent.winterSeason.showContent = enableWinterSeason;
          }
        },
        { classId: classroomId },
      );
    }
  },
  onSuccess: (_, { classroomId }) => {
    useClassroomFetcher.invalidateQueries();
    useAllClassroomFetcher.invalidateQueries();
    useMonsterverseConfigFetcher.invalidateQueries();
    useMonsterverseActivitiesFetcher.invalidateQueries({ classId: classroomId });
    useClassroomDojoIslandsSettingsFetcher.invalidateQueries({ classId: classroomId });
  },
});

type StudentAccountConsentParams = Partial<
  APIRequestParameters<"/api/dojoClass/{classId}/studentAccountConsent", "put">["path"]
> & {
  classroomId: string;
};
type StudentAccountConsentResponse = APIResponse<"/api/dojoClass/{classId}/studentAccountConsent", "put">;

export const useStudentAccountConsentOperation = makeMutation<
  StudentAccountConsentParams,
  StudentAccountConsentResponse
>({
  name: "updateStudentAccountConsent",
  fn: async ({ classroomId }) => {
    const { body } = await callApi({
      method: "PUT",
      path: `/api/dojoClass/${classroomId}/studentAccountConsent`,
    });

    return body;
  },
  onSuccess: () => {
    useClassroomFetcher.invalidateQueries();
  },
});

type AddClassroomCollaboratorParams = APIRequestParameters<"/api/dojoClass/{classId}/teacher", "post">["path"] &
  APIRequestBody<"/api/dojoClass/{classId}/teacher", "post"> & {
    classroomId: string;
  };
type AddClassroomCollaboratorResponse = APIResponse<"/api/dojoClass/{classId}/teacher", "post">;

export const useAddClassroomCollaboratorOperation = makeMutation<
  // TODO: TSM we 'rename' to classroomId to avoid modifying the runtime. Once all is typed we can rename and use the same name as in API
  Omit<AddClassroomCollaboratorParams, "classId">,
  AddClassroomCollaboratorResponse
>({
  name: "addClassroomCollaborator",
  async fn({ classroomId, emailAddress }) {
    try {
      const { body } = await callApi({
        method: "POST",
        path: `/api/dojoClass/${classroomId}/teacher`,
        body: { emailAddress },
      });
      return body;
    } catch (err: any) {
      if (err?.response?.status === 400) {
        return err;
      }
      throw err;
    }
  },
  onSuccess: () => {
    useClassroomFetcher.invalidateQueries();
  },
  onMutate: (params) => {
    useClassroomFetcher.setQueriesData((draft) => {
      if (params.classroomId === draft._id) {
        const { emailAddress } = params;
        const existingCollaborator = draft.collaborators?.find(
          (c: { emailAddress: string }) => c.emailAddress === emailAddress.toLowerCase(),
        );

        if (!existingCollaborator) {
          if (Array.isArray(draft.collaborators)) {
            draft.collaborators.push({ emailAddress, sharingStatus: "pending", collaboratorHasAccount: false });
          } else {
            draft.collaborators = [{ emailAddress, sharingStatus: "pending", collaboratorHasAccount: false }];
          }
        }
      }
    });
  },
});

type UpdateClassroomLoginMethodParams = Partial<
  APIRequestParameters<"/api/dojoClass/{classId}/loginSettings", "post">["path"]
> &
  APIRequestBody<"/api/dojoClass/{classId}/loginSettings", "post"> & {
    classroomId: string;
  };
type UpdateClassroomLoginMethodResponse = APIResponse<"/api/dojoClass/{classId}/loginSettings", "post">;

export const useUpdateClassroomLoginMethodOperation = makeMutation<
  UpdateClassroomLoginMethodParams,
  UpdateClassroomLoginMethodResponse
>({
  name: "updateClassroomLoginMethod",
  fn: async ({ classroomId, loginMethod }) => {
    const { body } = await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/loginSettings`,
      body: {
        loginMethod,
      },
    });

    return body;
  },
  onMutate: (params) => {
    useClassroomFetcher.setQueriesData((draft) => {
      if (draft._id === params.classroomId) {
        if (!draft.preferences) draft.preferences = { bubbleType: "combinedTotal" };
        draft.preferences.loginMethod = params.loginMethod;
      }
    });
  },
});

type CreateClassroomParams = APIRequestBody<"/api/dojoClass", "post"> & { schoolId?: string };
type CreateClassroomResponse = APIResponse<"/api/dojoClass", "post">;

export const useCreateClassroomOperation = makeMutation<CreateClassroomParams, CreateClassroomResponse>({
  name: "createClassroom",
  fn: async ({ name, year, iconNumber, pointsSharing, coTeachers }) => {
    try {
      const { body } = await callApi({
        method: "POST",
        path: "/api/dojoClass",
        query: {
          trackProgress: true,
        },
        body: {
          name,
          year,
          iconNumber,
          preferences: { pointsSharing },
          coTeachers,
        },
      });

      return body;
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err) {
      return err;
    }
  },
  onSuccess: (_data, params) => {
    useAllClassroomFetcher.invalidateQueries();
    if (params.schoolId) {
      useClassesFetcher.invalidateQueries({ schoolId: params.schoolId });
      useSchoolClassesFetcher.invalidateQueries({ schoolId: params.schoolId });
      useSchoolwidePointsDashboardFetcher.invalidateQueries({ schoolId: params.schoolId });
    } else {
      useClassesFetcher.invalidateQueries({ schoolId: "personal_classes" });
    }
  },
});

// TODO: TSM we 'rename' to classroomId to avoid modifying the runtime. Once all is typed we can rename and use the same name as in API
export type RemoveSelfAsClassroomCollaboratorParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/teacher/{emailAddress}", "delete">["path"],
  "classId"
> & {
  classroomId: string;
};
type RemoveSelfAsClassroomCollaboratorResponse = APIResponse<
  "/api/dojoClass/{classId}/teacher/{emailAddress}",
  "delete"
>;

export const useRemoveSelfAsClassroomCollaboratorOperation = makeMutation<
  RemoveSelfAsClassroomCollaboratorParams,
  { body: RemoveSelfAsClassroomCollaboratorResponse }
>({
  name: "removeSelfAsClassroomCollaborator",
  fn: async ({ classroomId, emailAddress }) => {
    return await callApi({
      method: "DELETE",
      path: `/api/dojoClass/${classroomId}/teacher/${emailAddress}`,
    });
  },
  onSuccess: (_data, _params) => {
    useAllClassroomFetcher.invalidateQueries();
    useClassesFetcher.invalidateQueries();
    useSchoolClassesFetcher.invalidateQueries();
    useClassroomFetcher.invalidateQueries();
    useClassWhereNotVerifiedInSchoolFetcher.invalidateQueries();
  },
});

type AcceptSharedClassroomParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/teacher/{emailAddress}", "post">["path"],
  "classId"
> & {
  classroomId: string;
};
type AcceptSharedClassroomResponse = APIResponse<"/api/dojoClass/{classId}/teacher/{emailAddress}", "post">;

export const useAcceptSharedClassroomOperation = makeMutation<
  AcceptSharedClassroomParams,
  { body: AcceptSharedClassroomResponse }
>({
  name: "acceptSharedClassroom",
  fn: async ({ classroomId, emailAddress }) => {
    return await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/teacher/${emailAddress}`,
    });
  },
  onMutate: (params) => {
    useSchoolClassesFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom: Classroom) => classroom._id === params.classroomId);
      if (classroomToUpdate) {
        classroomToUpdate.collaborators = classroomToUpdate?.collaborators?.map((collaborator: Collaborator) => {
          if (collaborator.emailAddress !== params.emailAddress) return collaborator;
          return { ...collaborator, sharingStatus: "accepted", title: "", firstName: "", lastName: "", _id: "" };
        });
      }
    });
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom) => classroom._id === params.classroomId);
      if (!classroomToUpdate) return;

      const collaboratorToUpdate = classroomToUpdate.collaborators?.find(
        (collaborator: { emailAddress: string }) => collaborator.emailAddress === params.emailAddress,
      );
      if (!collaboratorToUpdate) return;

      collaboratorToUpdate.sharingStatus = "accepted";
    });
    useClassesFetcher.invalidateQueries();
  },
});

type RejectSharedClassroomParams = Omit<
  APIRequestParameters<"/api/dojoClass/{classId}/teacher/{emailAddress}", "post">["path"],
  "classId"
> & {
  classroomId: string;
};
type RejectSharedClassroomResponse = APIResponse<"/api/dojoClass/{classId}/teacher/{emailAddress}", "post">;

export const useRejectSharedClassroomOperation = makeMutation<
  RejectSharedClassroomParams,
  RejectSharedClassroomResponse
>({
  name: "rejectSharedClassroom",
  fn: async ({ classroomId, emailAddress }) => {
    await callApi({
      method: "DELETE",
      path: `/api/dojoClass/${classroomId}/teacher/${emailAddress}`,
    });
  },
  onMutate: (params) => {
    useSchoolClassesFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom: Classroom) => classroom._id === params.classroomId);
      if (classroomToUpdate) {
        classroomToUpdate.collaborators = classroomToUpdate?.collaborators?.filter(
          (collaborator: Collaborator) => collaborator.emailAddress !== params.emailAddress,
        );
      }
    });
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomToUpdate = draft.find((classroom) => classroom._id === params.classroomId);
      if (!classroomToUpdate) return;

      draft.splice(draft.indexOf(classroomToUpdate), 1);
    });
    useClassesFetcher.invalidateQueries();
  },
});

export function sortClassroomsByName<C extends { name: string; demo?: boolean }>(
  classrooms: C[] = [],
  demoAtEnd?: boolean,
): C[] {
  const demoClassName = demoAtEnd ? "zzzzzz" : "";
  return classrooms.sort((a, b) =>
    (a.demo ? demoClassName : a.name.toLowerCase()).localeCompare(b.demo ? demoClassName : b.name.toLowerCase()),
  );
}

export const useClassroomPreferences = ({ classId }: { classId: string }): ClassroomPreferences => {
  const { data: classroom } = useClassroomFetcher({ id: classId });
  const tempPreferences = useSelector(
    useCallback((state: ClassroomSlice) => selectTempPreferences(state, classId), [classId]),
  );

  const defaultPreferences = useMemo(
    () => ({ ...getDefaultPreferences(classId), ...classroom?.preferences }),
    [classroom, classId],
  );

  return tempPreferences || defaultPreferences;
};

export const selectTempPreferences = (state: ClassroomSlice, classroomId: string): ClassroomPreferences =>
  state?.[STATE_KEY]?.tempPreferences?.[classroomId];

export function getDefaultPreferences(classroomId: string) {
  const showNegativeNotificationsDefault = !(
    classroomId > NO_NEGATIVE_BANNER_START && classroomId < NO_NEGATIVE_BANNER_END
  );
  return {
    avatarSize: 100,
    sort: "firstName",
    bubbleType: "combinedTotal",
    showAwardNotifications: {
      positive: true,
      negative: showNegativeNotificationsDefault,
    },
    playAwardSounds: {
      positive: true,
      negative: true,
    },
    hideLastNames: false,
    agendaWidgetPreferences: { time: true, todayActivity: true, activities: true },
  };
}

export const updateTempPreferences = ({ id, preferences }: { id: string; preferences: ClassroomPreferences }) => ({
  type: UPDATE_TEMP_PREFERENCES,
  payload: { id, preferences },
});

export const resetTempPreferences = (id: string) => ({
  type: RESET_TEMP_PREFERENCES,
  payload: { id },
});

export const setEditClassView = (view: string) => ({
  type: SET_EDIT_CLASS_MODAL_PANE,
  payload: { view },
});

export const userRequestedClass = (classroom: Classroom) =>
  classroom.collaborators?.some(
    (collaborator: Collaborator) => collaborator.isMe && collaborator.sharingStatus === "requested",
  );

export const userIsClassTeacher = (classroom: Classroom) =>
  classroom.collaborators?.some(
    (collaborator: Collaborator) =>
      collaborator.isMe && (collaborator.sharingStatus === "owner" || collaborator.sharingStatus === "accepted"),
  );

export const selectPreferences = (state: ClassroomSlice, classroom: Classroom): ClassroomPreferences => {
  const tempPreferences = selectTempPreferences(state, classroom._id);
  const classroomPreferences = Object.assign({}, classroom?.preferences);
  const result = tempPreferences || { ...getDefaultPreferences(classroom._id), ...classroomPreferences };

  return result;
};

const updateTempPreferencesHandler = createActionHandler(
  UPDATE_TEMP_PREFERENCES,
  ({ id, preferences }: { id: string; preferences: ClassroomPreferences }) =>
    (state: State): State => ({
      ...state,
      tempPreferences: {
        ...state.tempPreferences,
        [id]: preferences,
      },
    }),
);

const resetTempPreferencesHandler = createActionHandler(
  RESET_TEMP_PREFERENCES,
  ({ id }: { id: string }) =>
    (x: State) =>
      delete x.tempPreferences?.[id],
);

const finalReducer = combineActionHandlers(initialState, [updateTempPreferencesHandler, resetTempPreferencesHandler]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalReducer);
};

export default install;

// Checks if classroom/teacher eligible for no connected parents modal, and if it has been dismissed, but not whether the user
// is in the feature switch for it, which is checked in the modal component
export const useIsEligibleForNoConnectedParentsModal = ({ classroomId }: { classroomId: string }) => {
  const { data: classroom } = useClassroomFetcher({ id: classroomId });
  const teacher = useFetchedTeacher();
  const [didDismissNoConnectedParentsModal] = useLocalStorage<boolean | undefined>(
    "dismissedNoConnectedParentsModal",
    undefined,
  );

  const { data: userConfig } = useUserConfigFetcher({});
  const dismissedMetadataValue = getMetadataValue<string>(userConfig, HAS_DISMISSED_NO_CONNECTIONS_MODAL);

  const { data: school } = useSchoolFetcher(teacher && teacher.schoolId ? { id: teacher.schoolId } : NOOP);

  // still fetching data
  if (!teacher || !classroom) return false;
  const verified = isVerified(teacher, school?._id);

  const isDemoClass = classroom.demo;

  return Boolean(
    verified &&
      classroom.studentCount &&
      !classroom.parentCount &&
      !didDismissNoConnectedParentsModal &&
      !dismissedMetadataValue &&
      !isDemoClass &&
      !env.isTesting,
  );
};

// Events
export const addClassroomCollaboratorEvent = (location: string) =>
  logEvent({ eventName: `event.collaborators.invite_collaborator.${location}`, eventValue: "clicked" });

export const acceptSharedClassroomEvent = (location: string) =>
  logEvent({ eventName: `event.collaborators.accept_collaborator_invitation.${location}`, eventValue: "clicked" });

export const approveJoinRequestEvent = (location: string) =>
  logEvent({ eventName: `event.collaborators.accept_request_to_join_class.${location}`, eventValue: "clicked" });

export const rejectSharedClassroomEvent = (location: string) =>
  logEvent({ eventName: `event.collaborators.reject_collaborator_invitation.${location}`, eventValue: "clicked" });

export const rejectJoinRequestEvent = (location: string) =>
  logEvent({ eventName: `event.collaborators.reject_request_to_join_class.${location}`, eventValue: "clicked" });

// operations
type AddClassroomStudentsToDirectoryParams = APIRequestParameters<
  "/api/dojoClass/{classId}/addStudentsToDirectory",
  "post"
>["path"];

export const useAddClassroomStudentsToDirectoryOperation = makeMutation<AddClassroomStudentsToDirectoryParams, void>({
  name: "addClassroomStudentsToDirectory",
  fn: async ({ classId }) => {
    await callApi({
      method: "POST",
      path: `/api/dojoClass/${classId}/addStudentsToDirectory`,
      body: {},
    });

    logClient.logEvent("teach.web.addStudentsToDirectory.done");
  },
  onSuccess: (_data, params) => {
    useSchoolStudentsFetcher.invalidateQueries();
    useStudentsFetcher.invalidateQueries({ classId: params.classId });
    useNotificationsFetcher.invalidateQueries();
  },
});

export const shouldShowClassroomNotifBadge = (classroom: Classroom) => {
  const messageCount = classroom.unreadMessageCount || 0;
  const storyCount = classroom.unreadStoryPostCount || 0;
  const porftolioCount = classroom.pendingPostCount || 0;
  const notifCount = storyCount + porftolioCount;
  return messageCount === 0 && notifCount > 0;
};

export const useClassroomIcon = (classroom?: Classroom) => {
  const iconNumber = getClassroomIconNumber(classroom);
  const classIconSrc = useIconSrc("class", iconNumber);
  return classIconSrc ?? undefined;
};

export const getClassroomIconNumber = (classroom?: { icon: string } | { iconNumber: string }) => {
  const icon = isClassroomWithIcon(classroom) ? classroom.icon : classroom?.iconNumber;
  if (!icon || icon === "-1") return "0";
  return icon;
};

function isClassroomWithIcon(classroom?: { icon: string } | { iconNumber: string }): classroom is { icon: string } {
  return Boolean(classroom && "icon" in classroom && classroom.icon !== undefined);
}

export const useActiveNonDemoClasses = () => {
  const { data: allClassrooms, isFetching: classroomsLoading } = useAllClassroomFetcher({});
  const schoolId = useFetchedTeacherSelectedSchool()?.schoolId;
  const { data: userConfig, isFetching: userConfigLoading } = useUserConfigFetcher({});
  const forcedActiveClassrooms: Record<string, Date> = useMemo(
    () => getMetadataValue(userConfig, "forcedActiveClassrooms") || {},
    [userConfig],
  );

  if (classroomsLoading || userConfigLoading) {
    return { activeClassrooms: undefined, loading: true };
  }

  const activeClassrooms = allClassrooms?.filter((classroom: Classroom) => {
    const isActiveClass =
      !classroom.demo && !classroom.archived && isActive(classroom, forcedActiveClassrooms[classroom._id]);
    const isInSelectedSchool = classroom.schoolId === schoolId;
    return isActiveClass && isInSelectedSchool;
  });

  return { activeClassrooms, loading: false };
};

export const useClassWhereNotVerifiedInSchoolFetcher = makeCollectionQuery({
  fetcherName: "classWhereNotVerifiedInSchool",
  path: "/api/classWhereNotVerifiedInSchool",
});

export type ClassroomIdentifiers = {
  _id: string;
  name: string;
  schoolId: string | null;
  reason: string;
};

export type ClassFetcherData =
  | {
      verified: true;
      classroom: Classroom;
    }
  | {
      verified: false;
      classroom: ClassroomIdentifiers;
    };

// We are working to restrictClassAccessByRequiringSchoolVerification
// Some teachers have class_teacher rows for classes that belong to schools they are not verified in.
// So, when the personal classes feature switch is on, we need to fetch all classrooms and filter out the ones
export const useClassesMergedFetcher = () => {
  const allClassroomFetcher = useAllClassroomFetcher({});

  const classWhereNotVerifiedInSchoolFetcher = useClassWhereNotVerifiedInSchoolFetcher({});

  const notVerified = classWhereNotVerifiedInSchoolFetcher.data;

  // Merge the two fetchers. Only return data when both have resolved.
  let data: null | {
    verified: Classroom[];
    notVerified: ClassroomIdentifiers[];
    all: ClassFetcherData[];
  } = null;
  if (allClassroomFetcher.data && notVerified) {
    const notVerifiedIds = new Set(notVerified.map((c) => c._id));
    const verified = allClassroomFetcher.data.filter((classroom) => !notVerifiedIds.has(classroom._id));

    const allUnsorted = [
      ...verified.map((classroom): ClassFetcherData => ({ verified: true, classroom })),
      ...notVerified.map((classroom): ClassFetcherData => ({ verified: false, classroom })),
    ];

    const sortedClassrooms = sortClassroomsByName(allUnsorted.map((classroom) => classroom.classroom));
    const all = sortedClassrooms.map((s) => allUnsorted.find((c) => s._id === c.classroom._id)!);

    data = { verified, notVerified, all };
  }

  return {
    isLoading: allClassroomFetcher.isLoading || classWhereNotVerifiedInSchoolFetcher.isLoading,
    isError: allClassroomFetcher.isError || classWhereNotVerifiedInSchoolFetcher.isError,
    error: allClassroomFetcher.error || classWhereNotVerifiedInSchoolFetcher.error,
    data,
    refetch: () => {
      allClassroomFetcher.refetch();
      classWhereNotVerifiedInSchoolFetcher.refetch();
    },
  };
};

// Gets a classroom by id. Unlike useClassroomFetcher, this may return classes you are not verified in.
export const useClassFetcher = (classId: string) => {
  const classesMergedFetcher = useClassesMergedFetcher();

  // We need to also fetch the classroom by its id, because the collection routes return "your" classes,
  // but you may have "access" to other classes as an admin that the collections don't return.
  // So the collections are the source of truth, but the classroomFetcher is a fallback.
  const classroomFetcher = useClassroomFetcher({ id: classId });

  const foundClassInCollection = classesMergedFetcher.data?.all.find(
    (classroom) => classroom.classroom._id === classId,
  );

  const classesMergedFetcherIsSourceOfTruth =
    classesMergedFetcher.isLoading || classesMergedFetcher.isError || foundClassInCollection;

  if (classesMergedFetcherIsSourceOfTruth) {
    return {
      isLoading: classesMergedFetcher.isLoading,
      isError: classesMergedFetcher.isError,
      error: classesMergedFetcher.error,
      data: foundClassInCollection ?? null,
    };
  }

  // classroomFetcher might fail for a not verified class, so only return its errors if we don't use the
  // value from the merged fetcher.
  return {
    isLoading: classroomFetcher.isLoading,
    isError: classroomFetcher.isError,
    error: classroomFetcher.error,
    data: classroomFetcher.data
      ? {
          verified: true,
          classroom: classroomFetcher.data,
        }
      : null,
  };
};

type SidebarFetcherData = {
  active: Array<ClassFetcherData & { owner?: string }>;
  archived: Classroom[];
};

export function useSidebarClassesFetcher({ schoolId }: { schoolId?: "personal_classes" | string }) {
  if (!schoolId) schoolId = "personal_classes";
  const inPersonalClasses = schoolId === "personal_classes";
  const teacher = useFetchedTeacher();
  const showSchoolData = useShouldShowSchoolData();
  const classesMergedFetcher = useClassesMergedFetcher();
  const { data: school } = useSchoolFetcher(inPersonalClasses ? NOOP : { id: schoolId });
  const schoolTeachers = getSchoolTeachers(school);

  const [activeClassrooms, archivedRaw] = (classesMergedFetcher.data?.all || []).reduce(
    function (accumulator: ClassFetcherData[][], c) {
      if (!showSchoolData && c.classroom.schoolId && c.classroom.schoolId !== "personal_classes") {
        return accumulator;
      }

      accumulator[!c.verified || !c.classroom.archived ? 0 : 1].push(c);
      return accumulator;
    },
    [[], []],
  );

  // Note: this classes you are not verified in that are archived are not shown in the sidebar.
  const archived: Classroom[] = [];
  for (const classroom of archivedRaw) {
    assert(classroom.verified, "Archived classrooms should be verified");
    archived.push(classroom.classroom);
  }

  const activeClassroomsWithOwners = activeClassrooms?.map((c: ClassFetcherData) => ({
    ...c,
    owner: !c.verified
      ? undefined
      : c.classroom.teacher === teacher._id
        ? autoTranslate("you")
        : titleLast(schoolTeachers?.[c.classroom.teacher || ""]),
  }));

  function showClassInSidebar(classroom: ClassFetcherData) {
    // Note: this classes you are not verified in that are archived are not shown in the sidebar.
    if (!classroom.verified) return !(classroom.classroom as any).archived;
    if (classroom.verified && classroom.classroom.demo) return true;
    if (inPersonalClasses) return !classroom.classroom.schoolId;
    if (classroom.classroom.schoolId === schoolId) return true;
    if (classroom.classroom.schoolId && classroom.classroom.schoolId !== schoolId) return false;
    // Slightly hacky, the idea is that before you're verified, any classes
    // you make will not yet have a schoolId, but your intention was likely
    // to make them in the school you're pending in, so we show them.
    const isPendingInSelectedSchool = teacher.pendingSchools.some((s: { schoolId: string }) => s.schoolId === schoolId);
    if (isPendingInSelectedSchool && !classroom.classroom.schoolId) return true;
    return false;
  }

  const data: null | SidebarFetcherData = classesMergedFetcher.data
    ? {
        active: activeClassroomsWithOwners.filter(showClassInSidebar),
        archived,
      }
    : null;

  return {
    isLoading: classesMergedFetcher.isLoading,
    isError: classesMergedFetcher.isError,
    error: classesMergedFetcher.error,
    data,
  };
}
