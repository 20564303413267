import * as logClient from "@classdojo/log-client";
import { Message } from "@classdojo/web/pods/realtime/adapters/types";
import createAction from "@web-monorepo/infra/createAction";
import createWaitableSaga from "@web-monorepo/infra/createWaitableSaga";
import { refreshThread, getMyLatestMessageStart } from "@web-monorepo/shared/messaging/actions";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import map from "lodash/map";
import find from "lodash/find";
import type { AnyAction, Dispatch, Store } from "redux";
import { SagaIterator } from "redux-saga";
import { takeEvery, put, select } from "redux-saga/effects";
import { useSaveAttendanceOperation } from "app/pods/attendance";
import { SessionResponse, useSessionFetcher } from "app/pods/auth";
import { givePoint, giveRandom, resetRandom } from "app/pods/award";
import * as Award from "app/pods/award";
import * as Behavior from "app/pods/behavior";
import { useBehaviorsFetcher } from "app/pods/behavior";
import { Classroom, useClassroomFetcher } from "app/pods/classroom";
import { ClassroomGroup, useClassroomGroupsFetcher } from "app/pods/group";
import { refetchNotifications, Target } from "app/pods/notification";
import { useClassStoryPermalinkedPostFetcher, useClassStoryPostsFetcher } from "app/pods/story";
import { Student, useStudentsFetcher } from "app/pods/student/fetchers";
import pubnub from "app/pubnub";
import env from "app/utils/env";

type Award = {
  classroom: Classroom;
  behavior: Behavior.Behavior;
  students: Student[];
  groups: ClassroomGroup[];
  awardedAt: string;
  local: boolean;
};

const USE_PUBNUB_FOR_ADMIN_NOTIFICATIONS = false;
const STATE_KEY = "pubnub";

const lastSeenByClassId: { [key: string]: number } = {};
const ONE_DAY = 24 * 60 * 60 * 1000;

const sendProjectionMessage = (pubnubEventName: string, classId?: string) => {
  if (!classId) return;

  const isProjectionEvent = ["reward", "random"].includes(pubnubEventName);
  if (!isProjectionEvent) return;

  const lastSeen = lastSeenByClassId[classId] || 0;

  if (Date.now() - lastSeen < ONE_DAY) return;

  logClient.logEvent({
    eventName: "web.teacher.classroom.likely_projecting",
    eventValue: classId,
  });

  lastSeenByClassId[classId] = Date.now();
};

// ACTIONS
export const RECEIVE_MESSAGE = createAction("pubnub/receiveMessage");

// ACTION CREATORS

export const receiveMessage = (command: string, message: Message, pubnubChannelId: string) => {
  return {
    type: RECEIVE_MESSAGE,
    payload: {
      command,
      message,
      pubnubChannelId,
    },
  };
};

// SAGAS
function* receiveMessageSaga() {
  yield takeEvery(
    RECEIVE_MESSAGE,

    // eslint-disable-next-line complexity
    createWaitableSaga(function* ({ payload: { command, message, pubnubChannelId } }: AnyAction) {
      const currentClassroomId = getCurrentClassroomId();
      const payload = message;
      log("Received message", message);
      try {
        sendProjectionMessage(command, currentClassroomId);
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch {
        // Ignore errors
      }

      switch (command) {
        case "reward": {
          const award: Award = yield getAward(payload, currentClassroomId);
          if (!award) return;
          yield put(givePoint(award));
          return;
        }

        case "random":
          if (currentClassroomId === payload.class_id) {
            yield put(giveRandom(payload.data, payload.class_id, false));
          }
          return;

        case "unfocus":
          if (currentClassroomId === payload.aclass) {
            yield put(resetRandom());
          }
          return;

        case "refresh:session":
          yield put(refetchSession());
          return;

        case "message": {
          // for class-based messaging, fetches latest messages in the current thread
          // If we are not in a classroom then just ignore the message since
          // we don't need realtime notifications from outside a classroom.
          if (!currentClassroomId) return;

          // If message was sent to classroom owner and coteacher is listening on owners channel
          // coteacher shouldnt fetch their messages
          const session: SessionResponse = yield select(() => useSessionFetcher.getQueryData({}));
          const teacher = session?.teacher;
          if (pubnubChannelId && pubnubChannelId !== teacher?._id) {
            return;
          }

          const threadId = payload.payload.channelId;

          // thread UI side-effect
          yield put(refreshThread(threadId));
          return;
        }
        case "refresh-message-thread": {
          // If message was sent to classroom owner and coteacher is listening on owners channel
          // coteacher shouldnt fetch their messages
          const session: SessionResponse = yield select(() => useSessionFetcher.getQueryData({}));
          const teacher = session?.teacher;
          if (pubnubChannelId && pubnubChannelId !== teacher?._id) {
            return;
          }
          const { messageThreadId } = payload.payload;
          yield put(refreshThread(messageThreadId));
          return;
        }
        case "refresh-message": {
          // If message was sent to classroom owner and coteacher is listening on owners channel
          // coteacher shouldnt fetch their messages
          const session: SessionResponse = yield select(() => useSessionFetcher.getQueryData({}));
          const teacher = session?.teacher;
          if (pubnubChannelId && pubnubChannelId !== teacher?._id) {
            return;
          }
          yield put(getMyLatestMessageStart(payload.payload.messageThreadId));
          return;
        }
        case "notification": {
          const { recipient } = payload.payload;

          const session: SessionResponse = yield select(() => useSessionFetcher.getQueryData({}));
          const teacher = session?.teacher;
          if (recipient === teacher?._id) {
            // targeted notification: fetch immediately
            fetchNotifications(0);
          } else if (recipient === "teachers" || recipient === "global") {
            if (USE_PUBNUB_FOR_ADMIN_NOTIFICATIONS) {
              // mass notification: staggered fetch over ten minutes to ease server load
              fetchNotifications(Math.random() * 600000);
            }
          }
          return;
        }
        case "refresh:behaviors":
          yield put(refreshBehaviors(payload.aclass));
          return;

        default:
          log("No handler for command", command);
      }
    } as (...args: unknown[]) => SagaIterator),
  );
}

function* rewardSaga() {
  yield takeEvery(
    Award.GIVE_POINT_DONE,

    // I'm not sure what we're doing here without an iterator without a yield,
    // but I'm not going to touch it, just disable this rule for now, hopefully
    // sagas all go away soon.
    // eslint-disable-next-line require-yield
    createWaitableSaga(function* (action: AnyAction) {
      const {
        payload: { classroom, behavior, students, groups },
      } = action;

      pubnub.publish(
        {
          action: "reward",
          students: map(students, "_id"),
          classroom: classroom._id,
          aclass: classroom._id,
          behaviour: behavior._id,
          groups: map(groups, "_id"),
          groupNames: map(groups, "name"),
        },
        getClassOwner(classroom),
      );
    }),
  );
}

function* randomSaga() {
  yield takeEvery(
    Award.GIVE_RANDOM,

    createWaitableSaga(function* (action: AnyAction) {
      const {
        payload: { local, studentId, classroomId },
      } = action;

      if (local && classroomId) {
        const classroom: Classroom = yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));
        pubnub.publish(
          {
            action: "random",
            class_id: classroomId,
            data: studentId,
          },
          getClassOwner(classroom),
        );
      }
    }),
  );
}

function* unfocusSaga() {
  yield takeEvery(
    Award.RESET_RANDOM,

    createWaitableSaga(function* (action: AnyAction) {
      const {
        payload: { classroomId, local },
      } = action;

      if (local && classroomId) {
        const classroom: Classroom = yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));
        pubnub.publish(
          {
            action: "unfocus",
            aclass: classroomId,
          },
          getClassOwner(classroom),
        );
      }
    }),
  );
}

function* resetBubblesSaga() {
  yield takeEvery(
    Award.useSetPointsRunningTotalsOperation.isStartAction,

    createWaitableSaga(function* (action: AnyAction) {
      const { classroomId } = action.payload.params;
      const classroom: Classroom = yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));

      pubnub.publish(
        {
          action: "resetBubbles",
          aclass: classroomId,
        },
        getClassOwner(classroom),
      );
    }),
  );
}

function* undoAwardSaga() {
  yield takeEvery(
    Award.UNDO_DONE,

    createWaitableSaga(function* (action: AnyAction) {
      const { classroomId } = action.payload;
      const classroom: Classroom = yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));

      pubnub.publish(
        {
          action: "undoAward",
          aclass: classroomId,
        },
        getClassOwner(classroom),
      );
    }),
  );
}

type RefreshBehaviorsSagaActions =
  | Parameters<typeof Behavior.useRemoveBehaviorOperation.isDoneAction>[0]
  | Parameters<typeof Behavior.useEditBehaviorOperation.isDoneAction>[0]
  | Parameters<typeof Behavior.useCreateBehaviorOperation.isDoneAction>[0]
  | Parameters<typeof Behavior.useImportBehaviorsOperation.isDoneAction>[0];

function* refreshBehaviorsSaga() {
  yield takeEvery(
    [
      Behavior.useRemoveBehaviorOperation.isDoneAction,
      Behavior.useEditBehaviorOperation.isDoneAction,
      Behavior.useCreateBehaviorOperation.isDoneAction,
      Behavior.useImportBehaviorsOperation.isDoneAction,
    ],
    createWaitableSaga(function* (action: RefreshBehaviorsSagaActions) {
      const classroomId: string = Behavior.useImportBehaviorsOperation.isDoneAction(action)
        ? action.payload.params.body.to
        : action.payload.params.path.classId;
      const classroom: Classroom = yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));

      pubnub.publish(
        {
          action: "refresh:behaviors",
          aclass: classroomId,
        },
        getClassOwner(classroom),
      );
    }),
  );
}

function* saveAttendanceSaga() {
  yield takeEvery(
    (action: AnyAction) => useSaveAttendanceOperation.isDoneAction(action),
    function* (action: AnyAction) {
      const { classroomId } = action.payload.params;
      const classroom: Classroom = yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));

      pubnub.publish(
        {
          action: "attendanceChanged",
          aclass: classroomId,
        },
        getClassOwner(classroom),
      );
    },
  );
}

function getClassOwner(classroom: Classroom) {
  return classroom && classroom.teacher;
}

function getCurrentClassroomId() {
  // NOTE: we use hash routing, so the "pathname" will actually exist in Window.location.hash
  let result = /classes\/([a-f0-9]{24})/.exec(global?.location?.pathname);
  if (!result) {
    result = /classes\/([a-f0-9]{24})/.exec(global?.location?.hash);
  }
  if (!result) return;
  const [, classId] = result;
  return classId;
}

function getCurrentSchoolId() {
  // NOTE: we use hash routing, so the "pathname" will actually exist in Window.location.hash
  let result = /schools\/([a-f0-9]{24})/.exec(global?.location?.pathname);
  if (!result) {
    result = /schools\/([a-f0-9]{24})/.exec(global?.location?.hash);
  }
  if (!result) return;
  const [, schoolId] = result;
  return schoolId;
}

function getCurrentTarget() {
  const classId = getCurrentClassroomId();
  if (classId) {
    const target: Target = {
      type: "class",
      id: classId,
    };
    return target;
  }
  const schoolId = getCurrentSchoolId();
  if (schoolId) {
    const target: Target = {
      type: "school",
      id: schoolId,
    };
    return target;
  }
  return null;
}

let timeoutToken: number | undefined;

function fetchNotifications(timeBeforeFetch: number) {
  window.clearTimeout(timeoutToken);
  timeoutToken = window.setTimeout(() => {
    const target = getCurrentTarget();
    if (target) {
      refetchNotifications(target.type, target.id);
    }
    timeoutToken = undefined;
  }, timeBeforeFetch);
}

function log(...args: unknown[]) {
  if ((env.isDev || global.I_REALLY_WANT_PUBNUB_LOGS) && !global.hideLogs) {
    /* eslint-disable-next-line no-console */
    console.info("PUBNUB - ", ...args);
  }
}

const REFETCH_SESSION = "auth/refetchSession";
const refetchSession = () => ({
  type: REFETCH_SESSION,
});

const REFRESH_BEHAVIORS = "behaviors/refresh";
const refreshBehaviors = (classroomId: string) => ({ type: REFRESH_BEHAVIORS, payload: { classroomId } });

const pubnubMiddleware = (_store: Store) => (next: Dispatch) => (action: AnyAction) => {
  if (action.type === REFETCH_SESSION) {
    useSessionFetcher.invalidateQueries();
  } else if (action.type === REFRESH_BEHAVIORS) {
    useBehaviorsFetcher.invalidateQueries({ classId: action.payload.classroomId });
  } else if (action.type === RECEIVE_MESSAGE) {
    const { command, message } = action.payload;
    if (["resetBubbles", "undoAward"].includes(command)) {
      useStudentsFetcher.invalidateQueries({ classId: message.aclass });
      useClassroomGroupsFetcher.invalidateQueries({ classId: message.aclass });
    } else if (command === "refresh:groups") {
      useClassroomGroupsFetcher.invalidateQueries();
    } else if (command === "refresh:story") {
      useClassStoryPermalinkedPostFetcher.invalidateQueries({ classId: message.classroomId, postId: message.postId });
      useClassStoryPostsFetcher.invalidateQueries({ classId: message.classroomId });
    } else if (["attendanceChanged", "refresh:students", "reward"].includes(command)) {
      useStudentsFetcher.invalidateQueries({ classId: message.aclass });
    }
  }

  return next(action);
};

const install: PodInstallFunction = (installReducer, installSaga, _installPod, installMiddleware) => {
  installReducer(STATE_KEY, null);

  // @ts-expect-error Types of parameters '_store' and 'api' are incompatible.
  installMiddleware?.(pubnubMiddleware);

  installSaga(receiveMessageSaga);
  installSaga(rewardSaga);
  installSaga(randomSaga);
  installSaga(unfocusSaga);
  installSaga(saveAttendanceSaga);
  installSaga(resetBubblesSaga);
  installSaga(undoAwardSaga);
  installSaga(refreshBehaviorsSaga);
};

export default install;

function* getBehavior(classroomId: string, behaviorId: string) {
  const behaviors: Behavior.Behavior[] = yield select(() =>
    Behavior.useBehaviorsFetcher.getQueryData({
      classId: classroomId,
    }),
  );
  return find(behaviors, (item: Behavior.Behavior) => item._id === behaviorId);
}

function logIfNotArrayOrFalsey<T>(nonArray: T[] | undefined | null | unknown): void {
  if (!nonArray || Array.isArray(nonArray)) return;

  logClient.logException(new Error("unexpected non-array"), {
    nonArray,
    type: typeof nonArray,
  });
}

function* getStudents(classroomId: string, studentIds: string[]) {
  const students = [];
  const allStudents: Student[] = yield select(() => useStudentsFetcher.getQueryData({ classId: classroomId }));
  logIfNotArrayOrFalsey(allStudents);
  if (studentIds) {
    for (const id of studentIds) {
      const student = (allStudents || []).find((s) => s._id === id);
      if (student) {
        students.push(student);
      }
    }
  }
  return students;
}

function* getGroups(classroomId: string, groupIds: string[]) {
  const groups = [];
  if (groupIds) {
    const classroomGroups: ClassroomGroup[] = yield select(() =>
      useClassroomGroupsFetcher.getQueryData({
        classId: classroomId,
      }),
    );
    logIfNotArrayOrFalsey(classroomGroups);
    for (const groupId of groupIds) {
      const group = (classroomGroups || []).find((g) => g._id === groupId);
      if (group) {
        groups.push(group);
      }
    }
  }
  return groups;
}

function* getClassroom(classroomId: string): SagaIterator<Classroom> {
  return yield select(() => useClassroomFetcher.getQueryData({ id: classroomId }));
}

function* getAward(payload: AnyAction, currentClassroomId?: string) {
  const { aclass: classroomId, behaviour: behaviorId, students: studentIds, groups: groupIds } = payload;
  if (currentClassroomId !== classroomId) return;
  const classroom: Classroom = yield getClassroom(classroomId);

  if (!classroom) return;
  // hit a bug where stale data somewhere was sending pubnub reward events
  // to a teacher who no lonnger had access to the class. ClassroomStore.getPreferences()
  // would kick off a request for that classroom, causing a 403
  //
  // Also, led to discovery that point sounds occur while viewing class A if a point is
  // given in class B

  // This is an abuse of the store and we need this to go away once the pubnub handler is
  // better integrated into redux

  const behavior: Behavior.Behavior = yield getBehavior(classroomId, behaviorId);
  let students: Student[] = yield getStudents(classroomId, studentIds);
  const groups: ClassroomGroup[] = yield getGroups(classroomId, groupIds);
  if (groups.length && !students.length) {
    const allGroupStudentIds = groups.reduce<string[]>((acc, g) => {
      return [...acc, ...g.studentIds];
    }, []);
    students = yield getStudents(classroomId, allGroupStudentIds);
  }

  if (!behavior || !students.length) return;

  return {
    classroom,
    behavior,
    students,
    groups,
    awardedAt: new Date().toISOString(),
    local: false,
  };
}
