import { useSchoolDirectoryStudentsFetcher, useSchoolStudentsFetcher } from "#/app/pods/schoolStudents";
import { useStudentsFetcher } from "#/app/pods/student/fetchers";
import getCountryCode from "#/app/utils/getCountryCode";
import { APIRequestBody, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeApiMutation, makeCollectionQuery, makeMemberQuery } from "@web-monorepo/shared/reactQuery";
import { useSessionFetcher } from "app/pods/auth";
import { useAddStudentsModal, useSchoolVerificationFormModal } from "app/pods/modals";
import { TeacherSession, useFetchedTeacher } from "app/pods/teacher";
import Dictionary from "app/utils/dict";
import { isSchoolLeader, isVerified } from "app/utils/school";
import keyBy from "lodash/keyBy";
import { useSchoolVerificationRequestFetcher } from "./fetchers";

export type School = NonNullable<ReturnType<typeof useSchoolFetcher>["data"]>;
export type SchoolTeacher = NonNullable<School["schoolTeachers"]>[number];
export type SchoolInfo = NonNullable<School["info"]>;

export const getSchoolTeachers = (school: School | undefined): Dictionary<SchoolTeacher> | undefined => {
  return school ? keyBy(school.schoolTeachers, "_id") : undefined;
};

export function useIsVerifiedSchoolTeacher(teacher: TeacherSession, schoolId?: string) {
  return isVerified(teacher, schoolId);
}

export function useIsVerifiedForSelectedSchool() {
  const teacher = useFetchedTeacher();
  const selectedSchoolId = teacher.schools.find((school) => school.selected)?.schoolId;
  return isVerified(teacher, selectedSchoolId);
}

export const useSchoolsFetcher = makeCollectionQuery({
  path: "/api/schoolTeacher/schools",
  dontThrowOnStatusCodes: [401, 403, 404],
  fetcherName: "schools",
});

export const useSchoolFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{id}",
  query: { onlyVerified: "true" },
  dontThrowOnStatusCodes: [403, 404],
  fetcherName: "school",
});

export const useSchoolWithPendingTeachersFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{id}",
  dontThrowOnStatusCodes: [403, 404],
  fetcherName: "schoolWithPendingTeachers",
});

export const useSchoolUnreadNotificationCountFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{schoolId}/unreadNotificationCounts",
  dontThrowOnStatusCodes: [403, 404],
  fetcherName: "schoolUnreadNotificationCount",
});

export const useSchoolUnreadMessagesCountFetcher = makeMemberQuery({
  path: "/api/school/{schoolId}/teacher/{teacherId}/unreads",
  queryParams: ["includeBulletins"],
  dontThrowOnStatusCodes: [403, 404],
  fetcherName: "schoolUnreadMessagesCount",
});

export type CreateSchoolParams = { body: APIRequestBody<"/api/dojoSchool", "post"> };
export type CreateSchoolResponse = APIResponse<"/api/dojoSchool", "post">;

export const useCreateSchoolOperation = makeApiMutation({
  name: "createSchool",
  path: "/api/dojoSchool",
  method: "post",
});

export const useSchoolPickerCreateSchoolOperation = makeApiMutation({
  name: "createSchool",
  path: "/api/dojoSchool",
  method: "post",
  onMutate: () => {
    useSchoolsFetcher.invalidateQueries();
  },
  onSuccess: () => {
    useSessionFetcher.setQueriesData((draft) => {
      draft.teacher!.emailVerified = false;
    });
    useSessionFetcher.invalidateQueries();
    useSchoolsFetcher.invalidateQueries();
  },
});

export const useUpdateSchoolInfoOperation = makeApiMutation({
  name: "updateSchoolInfo",
  path: "/api/dojoSchool/{schoolId}/info",
  method: "put",
  onSuccess: (_data, params) => {
    useSchoolFetcher.invalidateQueries({ id: params.path.schoolId });
  },
});

export const useUpdateSchoolHighSchoolModeOperation = makeApiMutation({
  name: "updateSchoolHighSchoolMode",
  path: "/api/dojoSchool/{schoolId}/highSchoolMode",
  method: "put",
  onSuccess: (_data, params) => {
    useSchoolFetcher.invalidateQueries({ id: params.path.schoolId });
    useStudentsFetcher.invalidateQueries();
    useSchoolStudentsFetcher.invalidateQueries();
    useSchoolDirectoryStudentsFetcher.invalidateQueries();
  },
});

export const useUpdateSchoolMessagingSettingsOperation = makeApiMutation({
  name: "updateSchoolMessagingSettings",
  path: "/api/dojoSchool/{schoolId}/message-settings",
  method: "put",
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData(
      (draft) => {
        if ("messagingSettings" in draft) {
          draft.messagingSettings = {
            familyMessagingEnabled: false,
            classChatEnabled: true,
            ...draft.messagingSettings,
            ...params.body,
          };
        }
      },
      { id: params.path.schoolId },
    );
  },
  onSuccess: (_data, params) => {
    useSchoolFetcher.invalidateQueries({ id: params.path.schoolId });
  },
});

export const useStartCXSchoolVerificationFlow = () => {
  const { open: openForm } = useSchoolVerificationFormModal();
  const { close: hideAddStudentsModal } = useAddStudentsModal();
  const teacher = useFetchedTeacher();
  const hasRoleSL = isSchoolLeader(teacher);

  const eligibleCountries = ["us", "ca", "au", "uk", "nz"];
  const teacherCountryCode = getCountryCode(teacher) || "";
  const teacherInEligibleCountries = eligibleCountries.includes(teacherCountryCode);
  const eligibleForInAppSchoolVerificationFlow = hasRoleSL && teacher.emailVerified && teacherInEligibleCountries;

  const zendeskLink = "https://classdojo.zendesk.com/hc/requests/new?ticket_form_id=4886353625485";

  if (eligibleForInAppSchoolVerificationFlow) {
    return () => {
      hideAddStudentsModal();
      openForm();
    };
  } else {
    return () => window.open(zendeskLink, "_blank");
  }
};

export const useGetActiveSchoolVerificationRequest = () => {
  const teacher = useFetchedTeacher();
  const { data: schoolVerificationRequests } = useSchoolVerificationRequestFetcher({ teacherId: teacher._id });
  const activeSchoolVerificationRequests = schoolVerificationRequests?.filter((request) => {
    return request.verificationStatus === "pending" || request.verificationStatus === "inprogress";
  });
  return activeSchoolVerificationRequests;
};
