import { createSaveMessageDraftSaga } from "@classdojo/web/pods/messaging";
import { UploadedFileInfo } from "@classdojo/web/utils/uppy";
import callApi from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createAsyncActions from "@web-monorepo/infra/createAsyncActions";
import set from "lodash/set";
import { Dispatch } from "react";
import type { AnyAction, Store } from "redux";
import { put, takeEvery } from "redux-saga/effects";
import { PodInstallFunction } from "../podInfra";
import { queryClient } from "../reactQuery/queryClient";
import { makeQueryKey } from "../reactQuery/queryKey";
import {
  useMessageThreadFetcher,
  useMessagesFetcher,
  useParentMessageThreadsFetcher,
  useScheduledMessagesFetcher,
} from "./hooks";
import { Draft } from "./types";
import { localStorage } from "@web-monorepo/safe-browser-storage";

const STATE_KEY = "threadMessaging";
const LOCALSTORAGE_DRAFT_PREFIX = "thread_messaging";

const SAVE_MESSAGE_DRAFT = createAction("thread_messaging/save_message_draft");

export const REFRESH_MESSAGE_THREAD = createAction("thread_messaging/refresh_message_thread");

export const CLOSE_MESSAGE_THREAD = createAction("thread_messaging/close_message_thread");

export const [GET_MY_LATEST_MESSAGE, GET_MY_LATEST_MESSAGE_DONE] = createAsyncActions(
  "thread_messaging/get_my_latest_message",
);

type SaveMessageDraftAction = {
  type: typeof SAVE_MESSAGE_DRAFT;
  payload: {
    targetId: string;
    body: string;
    attachments?: UploadedFileInfo[];
  };
};
type RefreshMessageThreadAction = {
  type: typeof REFRESH_MESSAGE_THREAD;
  payload: {
    messageThreadId: string;
  };
};

type OtherAction = {
  type: "thread_messaging/other";
  payload: {
    other: boolean;
  };
};

type StaffMessagingAction = SaveMessageDraftAction | OtherAction;

export const saveDraft = (
  targetId: string,
  body: string,
  attachments?: UploadedFileInfo[],
): SaveMessageDraftAction => ({
  type: SAVE_MESSAGE_DRAFT,
  payload: { targetId, body, attachments },
});

type StaffMessagingState = {
  drafts: Record<string, { body: string; attachments: UploadedFileInfo[] }>;
};
export type StaffMessagingSlice = {
  [STATE_KEY]: StaffMessagingState;
};

const isSaveMessageDraftAction = (action: StaffMessagingAction): action is SaveMessageDraftAction => {
  return action.type === SAVE_MESSAGE_DRAFT;
};

const saveDraftHandler = (state: StaffMessagingState, action: StaffMessagingAction) => {
  if (isSaveMessageDraftAction(action)) {
    const { targetId, body, attachments } = action.payload;
    return set(state, `drafts.${targetId}`, { body, attachments });
  }

  return state;
};

export const selectDraft = (state: StaffMessagingSlice, messageThreadId: string): Draft => {
  if (state[STATE_KEY].drafts[messageThreadId] && state[STATE_KEY].drafts[messageThreadId]?.body != "") {
    return state[STATE_KEY].drafts[messageThreadId];
  } else {
    const draft = { body: localStorage.getItem(`${LOCALSTORAGE_DRAFT_PREFIX}.${messageThreadId}`) };
    return draft;
  }
};

export const refreshThread = (messageThreadId: string): RefreshMessageThreadAction => ({
  type: REFRESH_MESSAGE_THREAD,
  payload: { messageThreadId },
});

export const closeThread = (messageThreadId: string): RefreshMessageThreadAction => ({
  type: REFRESH_MESSAGE_THREAD,
  payload: { messageThreadId },
});

export const getMyLatestMessageStart = (messageThreadId: string) => ({
  type: GET_MY_LATEST_MESSAGE,
  payload: { messageThreadId },
});

function* getMyLatestMessage({ payload }: ReturnType<typeof getMyLatestMessageStart>) {
  const { messageThreadId } = payload;

  try {
    const { body } = yield callApi({
      method: "GET",
      path: `/api/message-thread/${messageThreadId}/my-latest-message`,
    });

    yield put({
      type: GET_MY_LATEST_MESSAGE_DONE,
      payload: {
        messageThreadId,
        data: body,
      },
    });
  } catch (err: any) {
    if (err.response && [403, 404].includes(err.response.status)) return;

    throw err;
  }
}

export function* getLatestMessageSaga() {
  yield takeEvery(GET_MY_LATEST_MESSAGE, getMyLatestMessage);
}

const initialState: StaffMessagingState = {
  drafts: {},
};

const reducer = combineActionHandlers(initialState, [saveDraftHandler]);

const isRefreshMessageThreadAction = (action: AnyAction): action is RefreshMessageThreadAction => {
  return action.type === REFRESH_MESSAGE_THREAD;
};

const isCloseMessageThreadAction = (action: AnyAction): action is RefreshMessageThreadAction => {
  return action.type === CLOSE_MESSAGE_THREAD;
};

const reduxMiddleware = (_store: Store) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (isRefreshMessageThreadAction(action)) {
    useParentMessageThreadsFetcher.invalidateQueries();
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: action.payload.messageThreadId });
    useScheduledMessagesFetcher.invalidateQueries({ messageThreadId: action.payload.messageThreadId });
    useMessagesFetcher.invalidateQueries({ messageThreadId: action.payload.messageThreadId });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadNotificationCount" }) });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "classMessageThreads" }) });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadMessagesCount" }) });
    queryClient.invalidateQueries({
      queryKey: makeQueryKey({ fetcherName: "schoolTeacherUnifiedInboxMessageThreads" }),
      refetchPage: (_page, index, _allPages) => index === 0,
    });
  } else if (isCloseMessageThreadAction(action)) {
    // TODO: Don't invalidate, mutate the fetchers and remove the data instead
    useParentMessageThreadsFetcher.invalidateQueries();
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: action.payload.messageThreadId });
    useScheduledMessagesFetcher.invalidateQueries({ messageThreadId: action.payload.messageThreadId });
    useMessagesFetcher.invalidateQueries({ messageThreadId: action.payload.messageThreadId });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadNotificationCount" }) });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "classMessageThreads" }) });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadMessagesCount" }) });
    queryClient.invalidateQueries({
      queryKey: makeQueryKey({ fetcherName: "schoolTeacherUnifiedInboxMessageThreads" }),
    });
  }

  return next(action);
};

const install: PodInstallFunction = (installReducer, installSaga, _, installMiddleware) => {
  installReducer(STATE_KEY, reducer);
  installSaga(createSaveMessageDraftSaga(SAVE_MESSAGE_DRAFT, localStorage, LOCALSTORAGE_DRAFT_PREFIX));
  installSaga(getLatestMessageSaga);
  installMiddleware!(reduxMiddleware as any);
};

export default install;
