import {
  type MessageThread,
  MessageThreadTypes,
  type AugmentedMessageThread,
  type AugmentedBulletinThread,
} from "./types";

export function isBulletinThread(
  t: MessageThread | AugmentedMessageThread | AugmentedBulletinThread | undefined,
): t is AugmentedBulletinThread {
  return t?.type === "bulletin";
}

export function isMessageThread(
  t: MessageThread | AugmentedMessageThread | AugmentedBulletinThread | undefined,
): t is MessageThread {
  return t?.type !== "bulletin";
}

export function isClassDmThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.classDm } {
  return isMessageThread(t) && t.type === MessageThreadTypes.classDm;
}

export function isDmThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.dm } {
  return isMessageThread(t) && t.type === MessageThreadTypes.dm;
}

export function isStaffThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.staff } {
  return isMessageThread(t) && t.type === MessageThreadTypes.staff;
}

export function isChatThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.chat } {
  return isMessageThread(t) && t.type === MessageThreadTypes.chat;
}

export function isStarterChatThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.starterChat } {
  return isMessageThread(t) && t.type === MessageThreadTypes.starterChat;
}

export function isClassAnnouncementThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.classAnnouncement } {
  return isMessageThread(t) && t.type === MessageThreadTypes.classAnnouncement;
}

export function isSchoolAnnouncementThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.schoolAnnouncement } {
  return isMessageThread(t) && t.type === MessageThreadTypes.schoolAnnouncement;
}

export function isSchoolChatThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.schoolChat } {
  return isMessageThread(t) && t.type === MessageThreadTypes.schoolChat;
}

export function isStaffMessagingThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.staff } {
  return isMessageThread(t) && t.type === MessageThreadTypes.staff;
}

export function isCommunityGroupStarterChatThread(
  t: MessageThread | AugmentedBulletinThread,
): t is MessageThread & { type: typeof MessageThreadTypes.communityGroupStarterChat } {
  return isMessageThread(t) && t.type === MessageThreadTypes.communityGroupStarterChat;
}

export function isAnnouncementMetadata(
  m: MessageThread["metadata"],
): m is Extract<MessageThread["metadata"], { class: { _id: string } }> {
  return "class" in m && !!m.class && "name" in m.class;
}

export function isClassDmMetadata(
  m: MessageThread["metadata"],
): m is Extract<MessageThread["metadata"], { student: { _id: string } }> {
  return "student" in m;
}

type ChatMetadata = {
  families: Array<{
    student: {
      _id: string;
      firstName: string;
      lastName: string;
    };
    parents: string[];
  }>;
  class?: { _id: string; familyMessagingEnabled: boolean };
  classes?: Array<{ _id: string }>;
  familyMessagingEnabled: boolean;
};

export function isChatMetadata(m: MessageThread["metadata"]): m is ChatMetadata {
  return "families" in m;
}

export function entityIsCommunityOwner(entity: { _id: string }, t: MessageThread): boolean {
  const entityParticipant = t.participants.find((p) => p._id === entity._id);
  return !!entityParticipant && "isOwner" in entityParticipant && !!entityParticipant.isOwner;
}
