import uniq from "lodash/uniq";
import type { Middleware, Reducer } from "redux";
import { Saga } from "redux-saga";

export type InstallReducer = (stateKey: string, reducer: Reducer | null) => void;

export interface PodInstallFunction {
  (
    installReducer: InstallReducer,
    installSaga: (saga: Saga) => void,
    installPod: (pod: PodInstallFunction) => void,
    installMiddleware?: (middleware: Middleware) => void,
  ): void;
}

export function flatten(podInstallFunctions: PodInstallFunction[]): {
  reducerMap: { [stateKey: string]: Reducer };
  sagas: Saga[];
  middleware: Middleware[];
} {
  const reducerMap: { [stateKey: string]: Reducer } = {};
  const sagas: Saga[] = [];
  const middlewareList: Middleware[] = [];

  function installReducer(stateKey: string, reducer: Reducer) {
    if (reducerMap[stateKey] && reducerMap[stateKey] !== reducer) {
      throw new Error(`Reducer already registered at path: ${stateKey}`);
    }

    reducerMap[stateKey] = reducer;
  }

  function installSaga(saga: Saga) {
    sagas.push(saga);
  }

  function installMiddleware(middleware: Middleware) {
    middlewareList.push(middleware);
  }

  function installPod(podInstallFunction: PodInstallFunction) {
    podInstallFunction(installReducer as any, installSaga, installPod, installMiddleware);
  }

  podInstallFunctions.forEach(installPod);

  return {
    reducerMap,
    sagas: uniq(sagas),
    middleware: uniq(middlewareList),
  };
}
