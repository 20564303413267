export class DojoError<TParams = undefined | unknown> extends Error {
  params: TParams;
  errorType: unknown;
  remainingAttempts?: number;

  constructor(name: string, params: TParams, errorType: unknown) {
    super(name);
    this.name = name;
    this.params = params;
    this.errorType = errorType;
  }

  is<T>(type: ErrorType<T>): this is DojoError<T> {
    return type === this.errorType;
  }
}

export type ErrorType<T> = {
  (params?: T): DojoError<T>;
  errorName: string;
};

//
// errorTypeMaker("api-auth") returns
// a function that returns a DojoError:  () => DojoError
//
// Where DojoError has a .is method to help identify errors
//
export function errorTypeMaker<T = undefined>(name: string): ErrorType<T> {
  if (!name) {
    throw new Error("Must provide a name for errors");
  }
  const ErrorType = function (params: T) {
    return new DojoError<T>(name, params, ErrorType);
  };
  ErrorType.errorName = name;
  // This error is reported by `student` even though it's in shared
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Type 'T | undefined' is not assignable to type 'T'.
  return ErrorType;
}
