import * as logClient from "@classdojo/log-client";
import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createWaitableSaga from "@web-monorepo/infra/createWaitableSaga";
import { APIRequestBody } from "@web-monorepo/shared/api/apiTypesHelper";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeMutation } from "@web-monorepo/shared/reactQuery";
import produce from "immer";
import find from "lodash/find";
import { AnyAction, Dispatch, Store } from "redux";
import { SagaIterator } from "redux-saga";
import { take, select, takeEvery, delay, put, SagaReturnType } from "redux-saga/effects";
import {
  GIVE_POINT,
  GIVE_POINT_DONE,
  GIVE_POINT_ERROR,
  givePoint,
  givePointSaga,
  givePointHandler,
  givePointDoneHandler,
  givePointErrorHandler,
  clearPopupAwardHandler,
} from "app/pods/award/data/givePoint";
import { GIVE_RANDOM, giveRandom, giveRandomHandler } from "app/pods/award/data/giveRandom";
import { resetRandom, resetRandomHandler, RESET_RANDOM } from "app/pods/award/data/resetRandom";
import {
  UNDO,
  UNDO_DONE,
  undo,
  resetUndoStack,
  undoSaga,
  undoHandler,
  resetUndoStackHandler,
  LastAward,
} from "app/pods/award/data/undo";
import genPointKey from "app/pods/award/util/genPointKey";
import { Behavior } from "app/pods/behavior";
import * as Classroom from "app/pods/classroom";
import { ClassroomGroup, useClassroomGroupsFetcher } from "app/pods/group";
import { IAwards, IRandomSelection } from "app/pods/points/components/Points";
import {
  useClassroomAndStudentAwardsFetcher,
  useClassroomAwardsFetcher,
  useRedemptionClassroomAndStudentFetcher,
  useRedemptionClassroomFetcher,
  useRedemptionStudentFetcher,
  useStudentAwardsFetcher,
} from "app/pods/reports";
import { ClassStudent } from "app/pods/student";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import sounds from "app/utils/sounds";
import { useSchoolwidePointsDashboardFetcher } from "#/src/pages/(sidebar)/schools/[schoolId]/points/_api/fetchers";

export {
  undo,
  resetUndoStack,
  givePoint,
  giveRandom,
  resetRandom,
  GIVE_RANDOM,
  RESET_RANDOM,
  GIVE_POINT,
  GIVE_POINT_DONE,
  GIVE_POINT_ERROR,
  UNDO,
  UNDO_DONE,
};

if (window.Cypress) {
  window.Cypress.sounds = sounds;
}

type RedeemPoints = RedeemStudentPointsParams & {
  createdAt?: string;
};

const STATE_KEY = "award";

export type AwardState = {
  undoStack: LastAward[];
  popupAward: IAwards | undefined;
  completedAwardWrites: Record<string, boolean>;
  randomSelection: IRandomSelection | undefined;
  popupRedeem: RedeemPoints | undefined;
};

type AwardSlice = {
  [STATE_KEY]: AwardState;
};

const initialState: AwardState = {
  undoStack: [],
  popupAward: undefined,
  completedAwardWrites: {},
  randomSelection: undefined,
  popupRedeem: undefined,
};

const CLEAR_POPUP_REWARD = createAction("award/clearRedeemPopup");

function* givePointPlaySoundSaga(): SagaIterator<void> {
  while (true) {
    const {
      payload: { classroom, behavior },
    }: { payload: { classroom: Classroom.Classroom; behavior: Behavior } } = yield take(GIVE_POINT);
    const preferences: SagaReturnType<typeof Classroom.selectPreferences> = yield select(
      Classroom.selectPreferences,
      classroom,
    );

    const soundPreferences = preferences.playAwardSounds;

    const currentClass = window.location.href.includes(classroom._id);

    if (soundPreferences && soundPreferences.positive && behavior.positive === true && currentClass) {
      sounds.play("p");
    }

    if (soundPreferences && soundPreferences.negative && behavior.positive === false && currentClass) {
      sounds.play(behavior.points === 0 ? "neutral-n" : "n");
    }
  }
}

const redeemPointsHandler = produce((draft: AwardState, action: { type: string }) => {
  if (useRedeemStudentPointsOperation.isDoneAction(action)) {
    draft.popupRedeem = action.payload.params;
  }

  if (action.type === CLEAR_POPUP_REWARD) {
    draft.popupRedeem = undefined;
  }
});

function* redeemPointsPopupDisplaySaga() {
  yield takeEvery(
    useRedeemStudentPointsOperation.isDoneAction,

    createWaitableSaga(function* (action: { payload: { params: RedeemStudentPointsParams } }) {
      const preferences: SagaReturnType<typeof Classroom.selectPreferences> = yield select(
        Classroom.selectPreferences,
        action.payload.params.classroom,
      );
      const soundPreferences = preferences.playAwardSounds;

      if (soundPreferences && soundPreferences.positive) {
        sounds.play("redeem-points");
      }

      yield delay(3000);
      yield put({
        type: CLEAR_POPUP_REWARD,
      });
    }),
  );
}

const finalAwardReducer = combineActionHandlers(initialState, [
  givePointHandler,
  givePointDoneHandler,
  givePointErrorHandler,
  clearPopupAwardHandler,
  resetRandomHandler,
  giveRandomHandler,
  undoHandler,
  resetUndoStackHandler,
  redeemPointsHandler,
]);

export const selectUndoStack = (x: AwardSlice) => x[STATE_KEY].undoStack ?? [];
export const selectCurrentPopupAward = (x: AwardSlice) => x[STATE_KEY].popupAward;
export const selectCurrentPopupRedeem = (x: AwardSlice) => x[STATE_KEY].popupRedeem;
export const selectRandomSelection = (x: AwardSlice) => x[STATE_KEY].randomSelection;

export const selectHasCompletedAwardWrite = (
  state: AwardSlice,
  payload: { classroom: Classroom.Classroom; awardedAt?: string },
) => state[STATE_KEY].completedAwardWrites[genPointKey(payload)] ?? false;

const awardMiddleware = (_store: Store) => (next: Dispatch) => (action: AnyAction) => {
  if (action.type === GIVE_POINT) {
    useClassroomGroupsFetcher.setQueriesData(
      (draft) => {
        const { behavior, groups } = action.payload;
        const weight = behavior.points;
        const pointsChange = Math.abs(weight);
        const pointsField = weight >= 0 ? "positivePoints" : "negativePoints";

        groups.forEach((awardedGroup: ClassroomGroup) => {
          const group = find(draft, (g: ClassroomGroup) => g._id === awardedGroup._id);
          if (group) {
            group[pointsField] = (group[pointsField] || 0) + pointsChange;
          }
        });
      },
      { classId: action.payload.classroom._id },
    );
  } else if (action.type === GIVE_POINT_DONE || action.type === GIVE_POINT_ERROR) {
    const eventName =
      action.type === GIVE_POINT_DONE
        ? `${logClient.getSite()}.mutation.awardBatch.success`
        : `${logClient.getSite()}.mutation.awardBatch.error`;
    logClient.logEvent({ eventName, automatedEvent: true });
    useStudentsFetcher.invalidateQueries();
    useStudentAwardsFetcher.invalidateQueries();
    useClassroomAwardsFetcher.invalidateQueries();
    useClassroomAndStudentAwardsFetcher.invalidateQueries();
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
  } else if (action.type === UNDO) {
    useClassroomGroupsFetcher.setQueriesData(
      (draft) => {
        const { behavior, groups } = action.payload;
        const weight = behavior.points;
        const pointsChange = Math.abs(weight);
        const pointsField = weight >= 0 ? "positivePoints" : "negativePoints";

        groups.forEach((awardedGroup: ClassroomGroup) => {
          const group = find(draft, (g: ClassroomGroup) => g._id === awardedGroup._id);
          if (group) {
            group[pointsField] = (group[pointsField] || 0) - pointsChange;
          }
        });
      },
      { classId: action.payload.classroom._id },
    );
    useStudentsFetcher.setQueriesData(
      (draft) => {
        const weight = action.payload.behavior.points;

        action.payload.students.forEach((student: ClassStudent) => {
          const draftStudent = find(draft, (item) => item._id === student._id);
          if (!draftStudent) return;

          draftStudent.currentPoints = draftStudent.currentPoints - weight;
          if (weight >= 0) {
            draftStudent.positivePoints = draftStudent.positivePoints - weight;
          } else {
            draftStudent.negativePoints = draftStudent.negativePoints + weight;
          }
        });
      },
      { classId: action.payload.classroom._id },
    );
  } else if (action.type === UNDO_DONE) {
    logClient.logEvent({ eventName: `${logClient.getSite()}.mutation.undoAwards.success`, automatedEvent: true });
  }

  return next(action);
};

const install: PodInstallFunction = (installReducer, installSaga, _installPod, installMiddleware) => {
  installReducer(STATE_KEY, finalAwardReducer);

  // @ts-expect-error Type 'MiddlewareAPI<Dispatch<AnyAction>, any>' is missing the following properties from type 'Store<any, AnyAction>': subscribe, replaceReducer, [Symbol.observable]
  installMiddleware?.(awardMiddleware);

  installSaga(givePointPlaySoundSaga);
  installSaga(givePointSaga);
  installSaga(undoSaga);
  installSaga(redeemPointsPopupDisplaySaga);
};

export default install;

type RedeemStudentPointsParams = Omit<
  APIRequestBody<"/api/dojoClass/{classId}/student/{studentId}/pointRedemption", "post">,
  "pointsRedeemed"
> & {
  classroom: Classroom.Classroom;
  student: ClassStudent;
  pointsToRedeem: APIRequestBody<
    "/api/dojoClass/{classId}/student/{studentId}/pointRedemption",
    "post"
  >["pointsRedeemed"];
};

export const useRedeemStudentPointsOperation = makeMutation<RedeemStudentPointsParams, CallApiDefaultResponse>({
  name: "redeemStudentPoints",
  fn: async ({ classroom, student, positive, negative, pointsToRedeem, rewardDescription }) =>
    callApi({
      method: "POST",
      path: `/api/dojoClass/${classroom._id}/student/${student._id}/pointRedemption`,
      body: {
        positive,
        negative,
        pointsRedeemed: pointsToRedeem,
        rewardDescription,
      },
    }),
  onSuccess: (_data, params) => {
    useStudentsFetcher.invalidateQueries({ classId: params.classroom._id });
    useRedemptionClassroomFetcher.invalidateQueries();
    useRedemptionClassroomAndStudentFetcher.invalidateQueries();
    useRedemptionStudentFetcher.invalidateQueries();
  },
});

export type PointMap = Record<string, { positive: number; negative: number }>;
type MergeStudentsParams = {
  classroomId: string;
  groupPointsMap: PointMap;
  studentPointsMap: PointMap;
};

export const useSetPointsRunningTotalsOperation = makeMutation<MergeStudentsParams, CallApiDefaultResponse>({
  name: "setPointsRunningTotals",
  fn: async ({ classroomId, studentPointsMap, groupPointsMap }) =>
    callApi({
      method: "PUT",
      path: `/api/dojoClass/${classroomId}/runningTotal`,
      body: {
        students: studentPointsMap,
        groups: groupPointsMap,
      },
    }),
  onMutate: (params) => {
    useClassroomGroupsFetcher.setQueriesData(
      (draft) => {
        const { groupPointsMap } = params;

        Object.keys(groupPointsMap).forEach((groupId: string) => {
          const group = find(draft, (g: ClassroomGroup) => g._id === groupId);
          if (group) {
            const { positive, negative } = groupPointsMap[groupId];
            group.positivePoints = positive;
            group.negativePoints = negative;
            //group.currentPoints = positive - negative;
          }
        });
      },
      { classId: params.classroomId },
    );

    useStudentsFetcher.setQueriesData(
      (draft) => {
        Object.keys(params.studentPointsMap).forEach((studentId) => {
          const draftStudent = find(draft, (item) => item._id === studentId);
          if (!draftStudent) return;

          const newTotal = params.studentPointsMap[studentId];
          draftStudent.positivePoints = newTotal.positive;
          draftStudent.negativePoints = newTotal.negative;
          draftStudent.currentPoints = newTotal.positive - newTotal.negative;
        });
      },
      { classId: params.classroomId },
    );
  },
});
