import type { SagaIterator } from "redux-saga";
import { call } from "redux-saga/effects";
import { waitForAsyncTasks, reset, start } from "./waitForAsyncTasks";

// re-exporting for backwards compatibility
export const waitForSagas = waitForAsyncTasks;
export { reset };

export default function createWaitableSaga(saga: (...args: any[]) => SagaIterator) {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function* waitableSaga(...args: unknown[]): Generator<any, any, any> {
    const complete = start();
    try {
      return yield call(saga, ...args);
    } catch (e) {
      complete();
      throw e;
    } finally {
      complete();
    }
  };
}
