import createAction from "@web-monorepo/infra/createAction";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { takeEvery, put, delay } from "redux-saga/effects";

export type BroadcastValueType = string | boolean;

type Payload = {
  id: string;
  value?: BroadcastValueType;
  duration?: number;
  nextValue?: BroadcastValueType;
};

const SEND_BROADCAST = createAction("broadcast/set-value");

const STATE_KEY = "broadcast";

type BroadcastState = Record<string, BroadcastValueType>;

export type BroadcastSlice = {
  [STATE_KEY]: BroadcastState;
};

const initialState: BroadcastState = {};

function broadcastReducer(state = initialState, { type, payload }: { type: string; payload: Payload }) {
  if (type !== SEND_BROADCAST) return state;
  const { id, value } = payload;
  return {
    ...state,
    [id]: value,
  };
}

function* reset({ payload }: { type: string; payload: Payload }) {
  if (payload.duration == null) return;
  yield delay(payload.duration);
  const { id, nextValue: value } = payload;
  yield put(setValue({ id, value }));
}

function* broadcastSaga() {
  yield takeEvery(SEND_BROADCAST, reset);
}

export const setValue = (data: Payload) => ({
  type: SEND_BROADCAST,
  payload: data,
});

export const selectValue = (state: BroadcastSlice, id: string) => state?.[STATE_KEY]?.[id];

const install: PodInstallFunction = (installReducer, installSaga) => {
  // @ts-expect-error Property 'payload' is missing in type 'AnyAction' but required in type '{ type: string; payload: Payload; }'.
  installReducer(STATE_KEY, broadcastReducer);
  installSaga(broadcastSaga);
};

export default install;
