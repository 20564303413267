import { AudienceMember } from "../types";

type AudienceFetcherResult = {
  data?: {
    _items: AudienceMember[];
  };
  isFetching: boolean;
};

type PartitionedAudience = {
  audience: AudienceMember[];
  audienceLoaded: boolean;
  parents: Extract<AudienceMember, { type: "parent" }>[];
  teachers: Extract<AudienceMember, { type: "teacher" }>[];
};

const partitionAudience = (audience: AudienceMember[] | undefined): PartitionedAudience => {
  if (!audience) {
    return { audience: [], audienceLoaded: false, teachers: [], parents: [] } as PartitionedAudience;
  }

  const [parents, teachers] = audience.reduce(
    (acc, next) => {
      if (next.type === "parent") {
        acc[0].push(next);
      } else if (next.type === "teacher") {
        acc[1].push(next);
      }

      return acc;
    },
    [[], []] as [
      parents: Extract<AudienceMember, { type: "parent" }>[],
      teachers: Extract<AudienceMember, { type: "teacher" }>[],
    ],
  );

  return { teachers, audienceLoaded: true, parents, audience };
};

export const useAudience = ({ fetcherResult }: { fetcherResult: AudienceFetcherResult }) => {
  return fetcherResult?.data?._items;
};

export const useClassAudience = ({ fetcherResult }: { fetcherResult: AudienceFetcherResult }) => {
  const audience = fetcherResult?.data?._items;
  return partitionAudience(audience);
};

export const useParentAudience = ({
  fetcherResult,
  isMessageThread,
}: {
  fetcherResult: AudienceFetcherResult;
  isMessageThread: boolean;
}) => {
  if (!isMessageThread) {
    return {
      audience: [],
      audienceLoaded: true,
      parents: [],
      teachers: [],
    };
  }
  const audience = fetcherResult?.data?._items;
  return partitionAudience(audience);
};
