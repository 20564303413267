import { isBulletinThread, isMessageThread } from "../thread-util";
import { AugmentedMessageThread, AugmentedBulletinThread } from "../types";

export const sortThreadsAndBulletinsUtil = (
  threads: AugmentedMessageThread[],
  bulletins: AugmentedBulletinThread[],
  allThreadsAreLoaded: boolean,
): (AugmentedMessageThread | AugmentedBulletinThread)[] => {
  if (!bulletins.length) {
    return threads;
  }

  return [...threads, ...bulletins]
    .sort((t1, t2) => {
      const t1SortDate = new Date(
        isBulletinThread(t1) ? t1.lastUpdatedDate : isMessageThread(t1) ? t1.lastUpdated : "",
      ).getTime();
      const t2SortDate = new Date(
        isBulletinThread(t2) ? t2.lastUpdatedDate : isMessageThread(t2) ? t2.lastUpdated : "",
      ).getTime();

      if (t1SortDate === t2SortDate) {
        return t2._id.localeCompare(t1._id);
      }

      return new Date(t2SortDate).getTime() - new Date(t1SortDate).getTime();
    })
    .filter((threadOrBulletin, i, sortedList) => {
      if (allThreadsAreLoaded) {
        // If everything is loaded, we know the bulletin is sorted in the right place.
        return true;
      }

      const currentThreadIsMessageThread = isMessageThread(threadOrBulletin);
      if (currentThreadIsMessageThread) {
        // We never remove a message thread, only a bulletin thread
        return true;
      }

      // If there is a message thread below this bulletin thread, keep the bulletin thread.
      const restOfList = sortedList.slice(i);
      const messageThreadsOlderThanBulletinExist = restOfList.some((t) => isMessageThread(t));
      const keepBulletin = messageThreadsOlderThanBulletinExist === true;

      return keepBulletin;
    });
};
