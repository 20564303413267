import { useSessionFetcher } from "#/app/pods/auth";
import { useClassroomFetcher } from "#/app/pods/classroom";
import { NOOP } from "@web-monorepo/shared/reactQuery";

/** useShouldShowSchoolData
 * We need to hide school data from teachers who have logged in to an SSO school
 * without SSO credentials.
 */
export function useShouldShowSchoolData(classId?: string): boolean {
  const { data: session } = useSessionFetcher({});
  const { data: classroom } = useClassroomFetcher(classId ? { id: classId } : NOOP);
  const schoolId = session?.teacher?.schoolId;

  // If the teacher is accessing a personal classroom, return true
  if (classId && !classroom?.schoolId) {
    return true;
  }

  // If the session teacher has no selected school, return true
  if (!schoolId) {
    return true;
  }

  const res = session?.teacher?.authAccessibleSchools?.some((school) => school.schoolId === schoolId);

  return Boolean(res);
}
