import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import set from "lodash/set";
import { useCallback } from "react";
import {
  AddStudentsContainerProps,
  AddStudentsWithRosterUploadExperimentProps,
} from "app/pods/addStudents/components/AddStudentsContainer";
import { EditClassStudentLoginsModalProps } from "app/pods/editClassroomInfo/components/EditClassStudentLoginsModal";
import { HomeConnectionsModalProps } from "app/pods/homeConnections/components/HomeConnectionsModal";
import { CreateClassroomModalProps } from "app/pods/launchpad-v2/modals/CreateClassroomModal";
import { AddCoTeacherModalProps } from "app/pods/schoolDirectory/modals/AddCoTeacherModal";
import { ArchiveClassModalProps } from "app/pods/schoolDirectory/modals/ArchiveClassModal";
import { TransferClassModalProps } from "app/pods/schoolDirectory/modals/TransferClassModal";
import { SchoolLeaderJoinClassModalProps } from "app/pods/story/components/class/SchoolLeaderJoinClassModal";
import { TransferClassesAndRemoveTeacherModalProps } from "app/pods/schoolDirectory/modals/TransferClassesAndRemoveTeacherModal";
import { useSelector, useDispatch } from "app/utils/reduxHooks";
import { StudentRosterUploadContainerProps } from "#/app/pods/addStudents/components/v2/StudentRosterUploadContainer";
import type { AddStudentsContainerProps as AddStudentsContainerV2Props } from "#/app/pods/addStudents/components/v2/AddStudentsContainer";

const STATE_KEY = "modals";

const OPEN_BY_NAME_AND_CLOSE_OTHERS = createAction(`modals/openByNameAndCloseOthers`);

const initialState: LocalReduxState = {};

type LocalReduxState = {
  [name: string]: { isOpen: boolean; props?: Record<string, unknown>; isBlocker?: boolean };
};
type ReduxState = {
  [STATE_KEY]: LocalReduxState;
};
export type UseModalHook = ReturnType<typeof setupModal>["useModal"];

const usedNames: string[] = [];

const subSelectorFor = (name: string) => `${name}`;

const selectOpen = (state: ReduxState) => {
  const modalStateByName = state[STATE_KEY];
  return Object.entries(modalStateByName)
    .filter(([, modalState]) => modalState.isOpen)
    .map(([name, modalState]) => ({ name, props: modalState.props, isBlocker: modalState.isBlocker }));
};

function setupModal<TProps>(name: string, isBlocker?: boolean) {
  if (usedNames.includes(name)) {
    throw new Error(`Modal name ${name} already used`);
  }
  usedNames.push(name);

  const subSelector = subSelectorFor(name);
  const selector = (state: ReduxState) => state[STATE_KEY][subSelector];

  initialState[subSelector] = { isOpen: false, isBlocker };

  const ACTION_TYPE = createAction(`modals/toggle_${name}`);
  const __handler = createActionHandler(ACTION_TYPE, ({ isOpen, props }: { isOpen: boolean; props: unknown }) => {
    return (state: object) => set({ ...state }, subSelector, { isOpen, props, isBlocker });
  });

  const openAction = (props?: TProps) => ({ type: ACTION_TYPE, payload: { isOpen: true, props, isBlocker } });
  const closeAction = () => ({ type: ACTION_TYPE, payload: { isOpen: false, isBlocker } });

  function useModal() {
    const modalState = useSelector(selector);
    const dispatch = useDispatch();
    const close = useCallback(() => dispatch(closeAction()), [dispatch]);
    const open = useCallback((props?: TProps) => dispatch(openAction(props)), [dispatch]);
    return { isOpen: modalState.isOpen, open, close };
  }

  return {
    useModal,
    openAction,
    closeAction,
    __handler,
  };
}

export const openByNameAndCloseOthers = (name: string, props?: unknown) => ({
  type: OPEN_BY_NAME_AND_CLOSE_OTHERS,
  payload: { name, props },
});

const openByNameAndCloseOthersHandler = createActionHandler(
  OPEN_BY_NAME_AND_CLOSE_OTHERS,
  ({ name, props }: { name: string; props?: unknown }, state: LocalReduxState) => {
    const modalToOpenSubSelector = subSelectorFor(name);
    if (state[modalToOpenSubSelector] == null) {
      // eslint-disable-next-line no-console
      console.warn(`No modal named '${name}' to toggle`);
      return state;
    }
    const keys = Object.keys(state);
    for (const key of keys) {
      const modalSubSelector = subSelectorFor(key);
      if (modalSubSelector === modalToOpenSubSelector) {
        state = set({ ...state }, `${modalToOpenSubSelector}.isOpen`, true);
        state = set({ ...state }, `${modalToOpenSubSelector}.props`, props);
      } else {
        state = set({ ...state }, `${modalSubSelector}.isOpen`, false);
      }
    }
    return state;
  },
);

export function useOpenModals() {
  return useSelector(selectOpen);
}

export const studentAccountConsentManagedModal = setupModal("StudentAccountConsentManagedModal");
export const useStudentAccountConsentManagedModal = studentAccountConsentManagedModal.useModal;

export const addStudents = setupModal<AddStudentsWithRosterUploadExperimentProps>(
  "AddStudentsWithRosterUploadExperiment",
);
export const useAddStudentsModal = addStudents.useModal;

export const addStudentsManually = setupModal<AddStudentsContainerProps>("AddStudentsContainer");
export const useAddStudentsManuallyModal = addStudentsManually.useModal;

export const studentsContainer = setupModal<AddStudentsContainerV2Props>("AddStudentsContainerV2");
export const useStudentsContainerModal = studentsContainer.useModal;

export const studentRoster = setupModal<StudentRosterUploadContainerProps>("StudentRosterUploadContainer");
export const useStudentRosterModal = studentRoster.useModal;

export const homeConnections = setupModal<HomeConnectionsModalProps>("HomeConnectionsModal");
export const useHomeConnectionsModal = homeConnections.useModal;

export const addCoTeacher = setupModal<AddCoTeacherModalProps>("AddCoTeacherModal");
export const useAddCoTeacherModal = addCoTeacher.useModal;

export const transferClass = setupModal<TransferClassModalProps>("TransferClassModal");
export const useTransferClassModal = transferClass.useModal;

export const transferClassesAndRemoveTeacherModal = setupModal<TransferClassesAndRemoveTeacherModalProps>(
  "TransferClassesAndRemoveTeacherModal",
);
export const useTransferClassesAndRemoveTeacherModal = transferClassesAndRemoveTeacherModal.useModal;

export const archiveClass = setupModal<ArchiveClassModalProps>("ArchiveClassModal");
export const useArchiveClassModal = archiveClass.useModal;

export const createClassroomModal = setupModal<CreateClassroomModalProps>("CreateClassroomModal");
export const useCreateClassroomModal = createClassroomModal.useModal;

export const privacyPolicyBlockerModal = setupModal<unknown>("PrivacyPolicyBlockerModal", true);
export const usePrivacyPolicyBlockerModal = privacyPolicyBlockerModal.useModal;

export const addCoTeachersModal = setupModal<void>("AddCoTeachersModal");
export const useAddCoTeachersModal = addCoTeachersModal.useModal;

export const inviteTeachersModal = setupModal("InviteTeachersModal");
export const useInviteTeachersModal = inviteTeachersModal.useModal;

export const teacherClassdojoSchoolModal = setupModal("TeacherClassdojoSchoolModal");
export const useTeacherClassdojoSchoolModal = teacherClassdojoSchoolModal.useModal;

export const teacherGetStartedModal = setupModal("TeacherGetStartedModal");
export const useTeacherGetStartedModal = teacherGetStartedModal.useModal;

export const schoolwideModal = setupModal("SchoolwideModalContainer");
export const useSchoolwideModal = schoolwideModal.useModal;

export const editSchoolwideTeacherCountModal = setupModal("EditSchoolwideTeacherCountModal");
export const useEditSchoolwideTeacherCountModal = editSchoolwideTeacherCountModal.useModal;

export const CdsStaffCompletedModal = setupModal("CdsStaffCompletedModal");
export const useCdsStaffCompletedModal = CdsStaffCompletedModal.useModal;

export const communityInvitationTeachersModal = setupModal("CommunityInvitationTeachersModal");
export const useCommunityInvitationTeachersModal = communityInvitationTeachersModal.useModal;

export const changeSchoolModal = setupModal("ChangeSchoolModal");
export const useChangeSchoolModal = changeSchoolModal.useModal;

export const schoolAdminExplainerModal = setupModal("SchoolAdminExplainerModal");
export const useSchoolAdminExplainerModal = schoolAdminExplainerModal.useModal;

export const viewSchoolAdminModal = setupModal("ViewSchoolAdminModal");
export const useViewSchoolAdminModal = viewSchoolAdminModal.useModal;

export const smsCommunityInvitationConfirmModal = setupModal("SmsCommunityInvitationConfirmModal");
export const useSmsCommunityInvitationConfirmModal = smsCommunityInvitationConfirmModal.useModal;

export const familyConnectionApprovalModal = setupModal("FamilyConnectionApprovalModal");
export const useFamilyConnectionApprovalModal = familyConnectionApprovalModal.useModal;

export const becomeMentorModal = setupModal("BecomeMentorModal");
export const useBecomeMentorModal = becomeMentorModal.useModal;

export const becomeMentorModal2023 = setupModal("BecomeMentorModal2023");
export const useBecomeMentorModal2023 = becomeMentorModal2023.useModal;

export const inviteSchoolLeaderModal = setupModal("InviteSchoolLeaderModal");
export const useInviteSchoolLeaderModal = inviteSchoolLeaderModal.useModal;

export const onboardingInviteSchoolLeaderModal = setupModal("OnboardingInviteSchoolLeaderModal");
export const useOnboardingInviteSchoolLeaderModal = onboardingInviteSchoolLeaderModal.useModal;

export const editClassStudentLoginsModal = setupModal<EditClassStudentLoginsModalProps>("EditClassStudentLoginsModal");
export const useEditClassStudentLoginsModal = editClassStudentLoginsModal.useModal;

export const dojoIslandsActivityDetailsModal = setupModal("DojoIslandsActivityDetailsModal");
export const useDojoIslandsActivityDetailsModal = dojoIslandsActivityDetailsModal.useModal;

export const schoolVerificationFormModal = setupModal("SchoolVerificationRequestForm");
export const useSchoolVerificationFormModal = schoolVerificationFormModal.useModal;

export const SchoolLeaderJoinClassModal = setupModal<SchoolLeaderJoinClassModalProps>("SchoolLeaderJoinClassModal");
export const useSchoolLeaderJoinClassModal = SchoolLeaderJoinClassModal.useModal;

export const EmailAddressConfirmModal = setupModal("EmailAddressConfirmModal");
export const useEmailAddressConfirmModal = EmailAddressConfirmModal.useModal;

export const ReOptInTeachersModal = setupModal("ReOptInTeachersModal");
export const useReOptInTeachersModal = ReOptInTeachersModal.useModal;

export const schoolAnnouncementNuxModal = setupModal("SchoolAnnouncementModal");
export const useSchoolAnnouncementNuxModal = schoolAnnouncementNuxModal.useModal;

export const AUNZMentorsEOYActivationModal = setupModal("AUNZMentorsEOYActivationModal");
export const useAUNZMentorsEOYActivationModal = AUNZMentorsEOYActivationModal.useModal;

export const mentorCelebrationModal = setupModal("MentorCelebrationModal");
export const useMentorCelebrationModal = mentorCelebrationModal.useModal;

export const shareSchoolwidePointsWithLeadersModal = setupModal("ShareSchoolwidePointsWithLeadersModal");
export const useShareSchoolwidePointsWithLeadersModal = shareSchoolwidePointsWithLeadersModal.useModal;

export const schoolwidePointsGivePointsNuxModal = setupModal("SchoolwidePointsGivePointsNuxModal");
export const useSchoolwidePointsGivePointsNuxModal = schoolwidePointsGivePointsNuxModal.useModal;

export const noSchoolwideBehaviorsModal = setupModal("NoSchoolwideBehaviorsModal");
export const useNoSchoolwideBehaviorsModal = noSchoolwideBehaviorsModal.useModal;

export const unifiedInboxNuxModal = setupModal("UnifiedInboxModal");
export const useUnifiedInboxNuxModal = unifiedInboxNuxModal.useModal;

export const MentorResourceKitPushModal = setupModal("MentorResourceKitPushModal");
export const useMentorResourceKitPushModal = MentorResourceKitPushModal.useModal;

export const EncourageSchoolInvitesModal = setupModal("EncourageSchoolInvitesModal");
export const useEncourageSchoolInvitesModal = EncourageSchoolInvitesModal.useModal;

export const MoreForMentors2024q4FollowupModal = setupModal("MoreForMentors2024q4FollowupModal");
export const useMoreForMentors2024q4FollowupModal = MoreForMentors2024q4FollowupModal.useModal;

export const FillOutSurveyQ3Modal = setupModal("FillOutSurveyQ3Modal");
export const useFillOutSurveyQ3Modal = FillOutSurveyQ3Modal.useModal;

export const MonsterOfTheYear2025Modal = setupModal("MonsterOfTheYear2025Modal");
export const useMonsterOfTheYear2025Modal = MonsterOfTheYear2025Modal.useModal;

export const schoolwidePointsRedeemNuxModal = setupModal("SchoolwidePointsRedeemNuxModal");
export const useSchoolwidePointsRedeemNuxModal = schoolwidePointsRedeemNuxModal.useModal;

export const Districts1PagerModal = setupModal("Districts1PagerModal");
export const useDistricts1PagerModal = Districts1PagerModal.useModal;

// ******************************************************
// Don't forget to add the handler to the array of handlers here!
// ******************************************************
const finalReducer = combineActionHandlers(initialState, [
  openByNameAndCloseOthersHandler,
  studentAccountConsentManagedModal.__handler,
  addStudents.__handler,
  addStudentsManually.__handler,
  studentRoster.__handler,
  homeConnections.__handler,
  addCoTeacher.__handler,
  transferClass.__handler,
  transferClassesAndRemoveTeacherModal.__handler,
  archiveClass.__handler,
  createClassroomModal.__handler,
  privacyPolicyBlockerModal.__handler,
  addCoTeachersModal.__handler,
  inviteTeachersModal.__handler,
  teacherClassdojoSchoolModal.__handler,
  teacherGetStartedModal.__handler,
  schoolwideModal.__handler,
  editSchoolwideTeacherCountModal.__handler,
  CdsStaffCompletedModal.__handler,
  communityInvitationTeachersModal.__handler,
  changeSchoolModal.__handler,
  schoolAdminExplainerModal.__handler,
  viewSchoolAdminModal.__handler,
  smsCommunityInvitationConfirmModal.__handler,
  familyConnectionApprovalModal.__handler,
  becomeMentorModal2023.__handler,
  inviteSchoolLeaderModal.__handler,
  editClassStudentLoginsModal.__handler,
  dojoIslandsActivityDetailsModal.__handler,
  schoolVerificationFormModal.__handler,
  onboardingInviteSchoolLeaderModal.__handler,
  SchoolLeaderJoinClassModal.__handler,
  EmailAddressConfirmModal.__handler,
  schoolAnnouncementNuxModal.__handler,
  AUNZMentorsEOYActivationModal.__handler,
  noSchoolwideBehaviorsModal.__handler,
  mentorCelebrationModal.__handler,
  shareSchoolwidePointsWithLeadersModal.__handler,
  schoolwidePointsGivePointsNuxModal.__handler,
  unifiedInboxNuxModal.__handler,
  ReOptInTeachersModal.__handler,
  MentorResourceKitPushModal.__handler,
  EncourageSchoolInvitesModal.__handler,
  MoreForMentors2024q4FollowupModal.__handler,
  FillOutSurveyQ3Modal.__handler,
  studentsContainer.__handler,
  MonsterOfTheYear2025Modal.__handler,
  schoolwidePointsRedeemNuxModal.__handler,
  Districts1PagerModal.__handler,
]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalReducer);
};

export default install;
