import { components } from "@classdojo/ts-api-types";
import type { MessageDetailsWithThreadId } from "@classdojo/web/pods/messaging/types";
import type { APIResponse, APIRequestBody } from "@classdojo/web/utils/apiTypesHelper";
export type { Draft, MessageDetails, MessageDetailsWithThreadId } from "@classdojo/web/pods/messaging/types";

export type TeacherClassMessageThread = APIResponse<
  "/api/teacher/{teacherId}/class/{classId}/message-thread",
  "get"
>["_items"][number];
export type StaffMessageThread = APIResponse<
  "/api/school/{schoolId}/teacher/{teacherId}/message-thread",
  "get"
>["_items"][number];
export type ParentCommunityMessageThread = APIResponse<
  "/api/communities/group/{groupId}/parent/{parentId}/message-thread",
  "get"
>["_items"][number];
export type MessageThread = TeacherClassMessageThread | StaffMessageThread | ParentCommunityMessageThread;

// We needed a more centralized way of filtering permissions on participants
export type AugmentedMessageThread = MessageThread & {
  activeParticipants: ThreadParticipant[];
  hasFailedMessages?: boolean;
  queryMatches?: string[];
};

export type TeacherMessageThreadSettings = APIResponse<
  "/api/teacher/{teacherId}/message-thread/{messageThreadId}/settings",
  "put"
>;

export type TeacherMessageSettings = APIResponse<"/api/teacher/{teacherId}/message_settings", "put">;

export type ThreadParticipant =
  | APIResponse<
      "/api/teacher/{teacherId}/class/{classId}/message-thread",
      "get"
    >["_items"][number]["participants"][number]
  | APIResponse<"/api/school/{schoolId}/staff-message-thread", "get">["_items"][number]["participants"][number];
export type MessageParticipant = components["schemas"]["ThreadedMessageResponse"]["sentTo"][number];

export type SchoolTeacher = NonNullable<School["schoolTeachers"]>[number];
export type School = APIResponse<"/api/dojoSchool/{id}", "get">;

export type Teacher = SchoolTeacher;

export type Parent = components["schemas"]["DojoSessionResponse"]["parent"];

export type CreateNewThreadOperationType = {
  path: { schoolId: string };
  body: { recipientIds: string[]; origin?: string };
};

export type CreateNewSchoolThreadOperationType = {
  path: { schoolId: string; teacherId: string };
  body: {
    participants: Array<{ _id: string; type: "parent" | "teacher" }>;
    origin?: string;
  };
};

export type Message = components["schemas"]["ThreadedMessageResponse"] & { isFailedMessage?: boolean };
export type RenderedDirectMessageAttachment = NonNullable<
  components["schemas"]["ThreadedMessageResponse"]["attachments"]
>[number];
export type RenderedMessageAttachment = Exclude<RenderedDirectMessageAttachment, { name: string }>;
export type RenderedStickerMessageAttachment = Extract<RenderedDirectMessageAttachment, { name: string }>;
export type MessageSender = components["schemas"]["ThreadedMessageResponse"]["sender"];

export type RenderedFailedMessage = Message & {
  messagePayload?: MessageDetailsWithThreadId;
};

export type ChunkSender = {
  _id: string;
  type: string;
  firstName?: string;
  lastName?: string;
  avatarURL?: string;
  isBlocked?: boolean;
};

export type MessageChunk = {
  sender: ChunkSender;
  messages: (Message | RenderedFailedMessage | BulletinMessage)[];
};

export type Contact = {
  _id: string;
  threadId: string;
  avatars: Array<{ avatarURL?: string; _id: string }>;
  avatarURL?: string;
  title: string;
  subtitle?: string;
  groupKey: string;
  filterKey: string;
  fullName: string;
  firstName: string;
  lastName: string;
  type: "teacher" | "parent";
  disabled?: boolean;
  classIds: string[];
  sortKey: string;
  isBlocked?: boolean;
  queryMatches?: string[];
  secondarySortKey: string;
};

// TODO: Return isBlocked from the API
export type AudienceMember = components["schemas"]["ItemsAudienceMember"]["_items"][number] & { isBlocked?: boolean };

type MessageThreadAudienceResponse = APIResponse<"/api/message-thread/{messageThreadId}/audience", "get">;
export type MessageThreadAudienceMember = MessageThreadAudienceResponse["_items"][0];

export type ClassAudienceResponse = APIResponse<"/api/class/{classId}/audience", "get">;
export type ParentAudienceResponse = APIResponse<"/api/parent/{parentId}/audience", "get">;

export type MessageBatchItem = APIResponse<"/api/message-thread/message-batch", "post">["_items"][number];

export type ReportMessageResponse = APIResponse<
  "/api/message-thread/{messageThreadId}/report/message/{messageId}",
  "post"
>;
export type ReportMessageRequestBody = APIRequestBody<
  "/api/message-thread/{messageThreadId}/report/message/{messageId}",
  "post"
>;
export type ReportedReason = components["schemas"]["ReportedReason"];

export type ReportParticipantResponse = APIResponse<
  "/api/message-thread/{messageThreadId}/report/entity/{entityId}",
  "post"
>;
export type ReportParticipantRequestBody = APIRequestBody<
  "/api/message-thread/{messageThreadId}/report/entity/{entityId}",
  "post"
>;

export type MessagesResponse = APIResponse<"/api/message-thread/{messageThreadId}/message", "get">;

export type MessageThreadEvent = Exclude<MessagesResponse["_items"][0]["event"], undefined>;

export const MessageThreadTypes = {
  staff: "t",
  classDm: "a",
  classAnnouncement: "b",
  chat: "c",
  dm: "d",
  starterChat: "e",
  schoolAnnouncement: "f",
  schoolChat: "g",
  schoolDm: "h",
  communityGroupStarterChat: "i",
} as const;

export type EntityMessageSettings = components["schemas"]["EntityMessagingSettingsObject"];
export type EntityMessageSettingsPayload = components["schemas"]["EntityMessageSettingsPutPayload"];

export type AnyAudienceMember = (AudienceMember | MessageThreadAudienceMember) & {
  queryMatches?: string[];
};

type BulletinThread = {
  _id: string;
  districtName: string;
  subject: string;
  lastUpdatedDate: string;
  unreadCount: number;
  type: string;
};

export type AugmentedBulletinThread = BulletinThread & {
  participants: any[];
};

export type BulletinMessage = {
  _id: string;
  subject: string;
  body: string;
  createdAt: string;
  sender: {
    _id: string;
    type: "district";
  };
  participants: any[];
};

export function isBulletinMessage(t: Message | BulletinMessage | undefined): t is BulletinMessage {
  return Boolean(t && t.sender.type === "district");
}

export function isMessage(t: Message | BulletinMessage | undefined): t is Message {
  return Boolean(t && t.sender.type !== "district");
}
