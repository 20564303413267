import { logEvent } from "@classdojo/log-client";
import { components } from "@classdojo/ts-api-types/api";
import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { APIRequestBody, APIRequestParameters } from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { useClassMessageThreadsFetcher } from "@web-monorepo/shared/messaging/hooks";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import produce from "immer";
import find from "lodash/find";
import reject from "lodash/reject";
import update from "lodash/update";
import errors from "app/errorTypes";
import { useClassroomFetcher } from "app/pods/classroom";
import {
  reloadSchoolStudent,
  useSchoolDirectoryStudentsFetcher,
  useSchoolStudentFetcher,
  useSchoolStudentsFetcher,
} from "app/pods/schoolStudents";
import { StudentPendingParentConnection } from "app/pods/student";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import { classStateMetadata } from "app/utils/class";
import isPhoneNumber from "app/validators/phoneNumber";
import { translate } from "app/utils/translate";

const STATE_KEY = "invitation";

type InvitationState = {
  failedInvitations: Record<string, DojoError>;
  pendingInvitations: Record<string, string | undefined>;
  failedDisconnections: Record<string, boolean>;
};

const initialState: InvitationState = {
  failedInvitations: {},
  pendingInvitations: {},
  // mapping parent id to boolean
  failedDisconnections: {},
};

const DISMISS_INVITATION_ERROR: string = createAction("invite/dismiss-error");

type CreateDismissInvitation = {
  name?: string;
  studentId: string;
  emailOrPhone?: string;
};

const dismissInvitationErrorHandler = createActionHandler(
  DISMISS_INVITATION_ERROR,
  ({ name, studentId, emailOrPhone }: CreateDismissInvitation) =>
    (state: InvitationState): InvitationState => {
      if (name && emailOrPhone) {
        return update(state, `failedInvitations.${studentId}`, (x) =>
          reject(x, {
            name,
            emailOrPhone,
          }),
        );
      }

      const { [studentId]: _, ...rest } = state.failedInvitations;

      return {
        ...state,
        failedInvitations: rest,
      };
    },
);

export const selectAllFailedDisconnections = (state: { [STATE_KEY]: InvitationState }) =>
  state?.[STATE_KEY].failedDisconnections;

const blockParentHandler = produce((draft, action) => {
  if (useBlockParentOperation.isErrorAction(action)) {
    draft.failedDisconnections[action.payload.params.parentId] = true;
  }
});

const inviteParentHandler = produce((draft: InvitationState, action) => {
  if (useInviteParentOperation.isStartAction(action)) {
    const { studentId, emailOrPhone } = action.payload.params;
    draft.pendingInvitations[studentId] = emailOrPhone;

    const inviteType = isPhoneNumber(emailOrPhone) ? "sms" : "email";
    logEvent({
      eventName: `parent_connections.send_${inviteType}_invite`,
      experiments: [],
    });
  }

  if (useInviteParentOperation.isDoneAction(action)) {
    const { studentId, emailOrPhone, inviteTrigger } = action.payload.params;
    delete draft.pendingInvitations[studentId];

    const inviteType = isPhoneNumber(emailOrPhone) ? "sms" : "email";
    logEvent({
      eventName: `parent_connections.send_${inviteType}_invite.success`,
      metadata: { invite_trigger: inviteTrigger, ...classStateMetadata() },
    });
  }

  if (useInviteParentOperation.isErrorAction(action)) {
    const { studentId, emailOrPhone } = action.payload.params;
    const inviteType = isPhoneNumber(emailOrPhone) ? "sms" : "email";
    delete draft.pendingInvitations[studentId];
    const errorMessage = action.payload.error.message;

    if (errorMessage.includes("cannot sms a landline")) {
      draft.failedInvitations[studentId] = errors.invitation.landline();
    } else if (errorMessage.includes("please supply a valid phone number")) {
      draft.failedInvitations[studentId] = errors.invitation.invalid();
    } else {
      draft.failedInvitations[studentId] = errors.invitation.failure();
    }

    logEvent({
      eventName: `parent_connections.send_${inviteType}_invite.failure`,
      metadata: { reason: action.payload.error.message },
    });
  }
});

const finalInvitationReducer = combineActionHandlers(initialState, [
  inviteParentHandler,
  dismissInvitationErrorHandler,
  blockParentHandler,
]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalInvitationReducer);
};

export default install;

// ---
// Fetcher

type BlockParentParams = APIRequestParameters<"/api/parent/{parentId}/student/{studentId}/block", "post">["path"] & {
  classroomId?: string;
  schoolId?: string;
};

export const useBlockParentOperation = makeMutation<BlockParentParams, CallApiDefaultResponse>({
  name: "blockParent",
  async fn({ parentId, studentId }) {
    try {
      return await callApi({
        method: "POST",
        path: `/api/parent/${parentId}/student/${studentId}/block`,
      });
    } catch (err: any) {
      if (err.response.status === 400) {
        return err;
      }

      throw err;
    }
  },
  onSuccess: (_data, params) => {
    if (params.classroomId) {
      useStudentsFetcher.invalidateQueries({ classId: params.classroomId });
    }
    useSchoolStudentFetcher.invalidateQueries({ schoolId: params.schoolId, studentId: params.studentId });
    useClassMessageThreadsFetcher.invalidateQueries({ classId: params.classroomId });
    reloadSchoolStudent(params.studentId, params.schoolId, params.classroomId);
  },
});

type UnblockParentParams = APIRequestParameters<
  "/api/parent/{parentId}/student/{studentId}/block",
  "delete"
>["path"] & {
  classroomId?: string;
  schoolId?: string;
};

export const useUnblockParentOperation = makeMutation<UnblockParentParams, CallApiDefaultResponse>({
  name: "unblockParent",
  async fn({ parentId, studentId }) {
    return await callApi({
      method: "DELETE",
      path: `/api/parent/${parentId}/student/${studentId}/block`,
    });
  },
  onSuccess: (_data, params) => {
    if (params.classroomId) {
      useStudentsFetcher.invalidateQueries({ classId: params.classroomId });
    }
    useSchoolStudentFetcher.invalidateQueries({ schoolId: params.schoolId, studentId: params.studentId });
    useClassMessageThreadsFetcher.invalidateQueries({ classId: params.classroomId });
    reloadSchoolStudent(params.studentId, params.schoolId, params.classroomId);
  },
});

export type InviteParentParams = APIRequestBody<"/api/invitation", "post"> & {
  schoolId?: string;
  skipSend?: boolean;
  classroomId?: string;
  inviteTrigger: "send" | "resend" | "additional_parent";
};

type StudentWithConnection = Pick<components["schemas"]["StudentResponse"], "_id" | "parentConnections">;
const addPendingParentConnection = (draft: StudentWithConnection[], studentId: string, emailOrPhone?: string) => {
  const draftStudent = find(draft, (item) => item._id === studentId);
  if (!draftStudent) return;

  addPendingParentConnectionToStudent(draftStudent, emailOrPhone);
};

const addPendingParentConnectionToStudent = (
  draftStudent: StudentWithConnection,
  emailOrPhone?: string,
  invitationId?: string,
) => {
  const isResend = find(
    draftStudent.parentConnections || [],
    // TODO: parentConnection here may not have a phone number, so typing this ad-hoc for this search.
    (c: { phoneNumber?: string; emailAddress?: string }) =>
      c.emailAddress === emailOrPhone || c.phoneNumber === emailOrPhone,
  );
  if (!isResend) {
    if (!draftStudent.parentConnections) draftStudent.parentConnections = [];
    // @fetcher-revisit could not assign data[draftStudentIndex].parentConnections = ...
    draftStudent.parentConnections.push({
      invitationId: invitationId ? invitationId : "",
      status: "pending",
      // don't worry if this is actually a phone, will be overwritten when api res finishes
      emailAddress: emailOrPhone,
    });
  }
};

export const useInviteParentOperation = makeMutation<InviteParentParams, CallApiDefaultResponse>({
  name: "inviteParent",
  async fn({ studentId, emailOrPhone, skipSend }) {
    try {
      return await callApi({
        method: "POST",
        path: "/api/invitation",
        body: {
          emailOrPhone,
          skipSend,
          source: "default",
          studentId,
        },
      });
    } catch (err: any) {
      if (err.response.status === 400) {
        return err;
      }

      throw err;
    }
  },
  onMutate: (params) => {
    const { emailOrPhone, studentId, schoolId } = params;

    if (params.classroomId) {
      useStudentsFetcher.setQueriesData((draft) => addPendingParentConnection(draft, studentId, emailOrPhone), {
        classId: params.classroomId,
      });
    }

    useSchoolStudentFetcher.setQueriesData((draft) => {
      if (schoolId === draft.schoolId && studentId === draft._id) {
        addPendingParentConnectionToStudent(draft, emailOrPhone, "new_id");
      }
    });

    useSchoolStudentsFetcher.setQueriesData((draft) => {
      if (draft?.length > 0 && schoolId === draft[0].schoolId) {
        addPendingParentConnection(draft, params.studentId, params.emailOrPhone);
      }
    });
  },
  onSuccess: (_data, params) => {
    useClassroomFetcher.invalidateQueries();
    useSchoolStudentFetcher.invalidateQueries({ schoolId: params.schoolId, studentId: params.studentId });
    useSchoolDirectoryStudentsFetcher.invalidateQueries({ schoolId: params.schoolId });
  },
  onSettled: (_data, _error, params) => {
    if (params.classroomId) {
      useStudentsFetcher.invalidateQueries({ classId: params.classroomId });
    }
    reloadSchoolStudent(params.studentId, params.schoolId, params.classroomId);
  },
  onError: (_data, params) => {
    useSchoolStudentFetcher.invalidateQueries({ schoolId: params.schoolId, studentId: params.studentId });
  },
});

export const getParentInviteErrorMessage = (errorMessage: string, studentName: string) => {
  if (errorMessage.includes("cannot sms a landline")) {
    return translate({ str: "dojo.teacher_web:parent_group_view.something_wrong_sms_invitation" });
  } else if (errorMessage.includes("please supply a valid phone number")) {
    return translate({
      str: "dojo.teacher_web:parent_group_view.failed_invalid_phone_number",
      subs: { studentName },
    });
  } else {
    return translate({ str: "dojo.teacher_web:parent_group_view.something_wrong_invitation", subs: { studentName } });
  }
};

type CancelParentInviteParams = {
  connection: StudentPendingParentConnection;
  studentId: string;
  schoolId?: string;
  classroomId?: string;
};

export const useCancelPendingParentInviteOperation = makeMutation<CancelParentInviteParams, CallApiDefaultResponse>({
  name: "cancelPendingParentInvite",
  async fn({ connection }) {
    try {
      return await callApi({
        method: "DELETE",
        path: `/api/invitation/${connection.invitationId}`,
        body: {
          status: "cancel",
        },
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err: any) {
      return err;
    }
  },
  onSuccess: (_data, params) => {
    if (params.classroomId) {
      useStudentsFetcher.invalidateQueries({ classId: params.classroomId });
    }
    useSchoolStudentFetcher.invalidateQueries({ schoolId: params.schoolId, studentId: params.studentId });
    reloadSchoolStudent(params.studentId, params.schoolId, params.classroomId);
  },
});

export type InviteParentViaSmsParams = APIRequestBody<"/api/invitation", "post"> & {
  schoolId?: string;
  classroomId: string;
  customText: string;
  inviteTrigger: "send" | "resend" | "additional_parent";
};

export const useStudentWithoutFamilyContactInfoOperation = makeMutation({
  name: "studentWithoutFamilyContactInfo",
  async fn({ studentId, classId }: { studentId: string; classId: string }) {
    try {
      return await callApi({
        method: "POST",
        path: `/api/dojoClass/${classId}/studentWithoutFamilyContactInfo/${studentId}`,
      });
    } catch (err: any) {
      if (err.response.status === 400) {
        return err;
      }

      throw err;
    }
  },
  onSuccess: () => {
    useClassroomFetcher.invalidateQueries();
  },
});

export const useTeacherCapabilitiesFetcher = makeMemberQuery({
  path: "/api/teacher/capabilities",
  fetcherName: "teacherCapabilities",
});

export const useStudentParentCodeMemberFetcher = makeMemberQuery({
  path: "/api/student/{studentId}/parentCode",
  fetcherName: "studentParentCode",
});

export const downloadAsAttachment = (url: string) => {
  const name = decodeURIComponent(decodeURIComponent(url.split("/").pop() || url));

  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      if (name && name.length) a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
};
