import { makeApiMutation, makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export const useSchoolLinkInviteMemberFetcher = makeMemberQuery({
  fetcherName: "schoolLinkInviteMemberFetcher",
  path: "/api/schoolLinkInvitation/{code}",
  dontThrowOnStatusCodes: [404],
});

export const useEmailValidationFetcher = makeMemberQuery({
  fetcherName: "emailValidation",
  path: "/api/user/emailValidation/{email}",
});

export const useGetOIDCProfile = makeMemberQuery({
  fetcherName: "oidcProfile",
  path: "/api/oidc/profile",
  dontThrowOnStatusCodes: [400],
});

export const useAccountLink = makeApiMutation({
  name: "accountLink",
  path: "/api/oidc/link",
  method: "post",
});

export function matchesSchoolEmailDomains(teacherEmail: string, schoolDomains: string[] | undefined) {
  return schoolDomains?.some((domain) => domain === teacherEmail.split("@")[1]);
}
