import { useOnFirstRender } from "@web-monorepo/hooks";
import callApi from "@web-monorepo/infra/callApi";
import { APIRequestBody, APIResponse, EndpointQueryParameters } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import { CLASSROOM_FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { useFetchedTeacher } from "app/pods/teacher";
import { useMemo } from "react";
import { MemberQueryType } from "@web-monorepo/shared/reactQuery/memberQuery";
import locales from "app/data/locales";
import { showNumberShoresBanner } from "./componentsV3/NumberShoresBanner";

export const DOJO_ISLANDS_URL = "https://www.classdojo.com/dojo-islands/";

export const DOJO_ISLAND = "Dojo Island";

export const DOJO_ISLANDS_NAME = "Dojo Islands";

export const DOJO_ISLANDS_NAME_WITHOUT_BETA = "Dojo Islands";

export const useMonsterverseConfigFetcher = makeMemberQuery({
  path: `/api/monsterverseConfig`,
  fetcherName: "monsterverseConfig",
  queryParams: ["classId"],
});

// TODO: Consider integrating this into core FS library?
// @ts-expect-error Types of parameters 'params' and 'params' are incompatible.
const useClassFSFetcher: MemberQueryType<
  "/api/featureSwitch",
  { featureSetId: string } & EndpointQueryParameters<"/api/featureSwitch">
> = makeMemberQuery({
  fetcherName: "classFeatureSwitches",
  path: "/api/featureSwitch",
  queryParams: ["featureSetId", "classId", "classSwitches"],
});

export const useMonsterverseEnabled = (classId?: string): boolean => {
  const { data } = useMonsterverseConfigFetcher({ classId });
  return (data?.type === "teacher" && data?.monsterverseEligible) || false;
};

export const useClassroomFeatureSwitches = (classId: string) => {
  const query: Record<string, unknown> = {};
  query.classId = classId;
  query.classSwitches = encodeURIComponent(Object.values(CLASSROOM_FEATURE_SWITCHES).join(","));

  const { data: switches } = useClassFSFetcher(query);
  return switches;
};

export const useFetchedClassroomFeatureSwitchVariant = (classId: string, switchName: string): string | undefined => {
  const fs = useClassroomFeatureSwitches(classId);
  return fs?.class?.[switchName] as string | undefined;
};

export const useShowTeacherDojoIslandsSettingsType = (classId?: string): "legacy" | "opt-out" | "permissions_v3" => {
  const permissionsV3Settings = useShowTeacherClassIslandsPermissionsV3(classId);
  const optOutSettings = useShowTeacherClassIslandsOptOut(classId);
  if (permissionsV3Settings) {
    return "permissions_v3";
  }
  if (optOutSettings) {
    return "opt-out";
  }
  return "legacy";
};

const useShowTeacherClassIslandsOptOut = (classId?: string): boolean => {
  const { data } = useMonsterverseConfigFetcher({ classId });
  return (data?.type === "teacher" && data?.allowTeacherToOptOutClassIslands) || false;
};

const useShowTeacherClassIslandsPermissionsV3 = (classId?: string): boolean => {
  const { data } = useMonsterverseConfigFetcher({ classId });
  return (data?.type === "teacher" && data?.allowTeacherToSetPermissionsV3_1) || false;
};

export const useMonsterverseConfiguration = (classId?: string) => {
  const { data: monsterverseConfig } = useMonsterverseConfigFetcher({ classId });
  if (monsterverseConfig?.type === "teacher") {
    return monsterverseConfig.config;
  }
};

export const useMonsterverseSeasonalContent = (classId?: string) => {
  const { data: monsterverseConfig } = useMonsterverseConfigFetcher({ classId });
  if (monsterverseConfig?.type === "teacher") {
    return monsterverseConfig.seasonalContent;
  }
};

export const useDojoIslandsOnboardingFetcher = makeMemberQuery({
  path: `/api/dojoClass/{classId}/dojoIslandsOnboarding`,
  fetcherName: "dojoIslandsOnboarding",
});

type DojoIslandsOnboardingResponse = APIResponse<"/api/dojoClass/{classId}/dojoIslandsOnboarding", "get">;
type DojoIslandsOnboardingStepId = keyof DojoIslandsOnboardingResponse;
type DojoIslandsOnboardingStatus = DojoIslandsOnboardingResponse["tryDojoIslands"]["status"];

type UpdateDojoIslandsOnboardingParams = {
  classId: string;
  stepId: DojoIslandsOnboardingStepId;
  status: DojoIslandsOnboardingStatus;
};
type UpdateDojoIslandsOnboardingbody = APIRequestBody<"/api/dojoClass/{classId}/dojoIslandsOnboarding", "patch">;

export const useDojoIslandsOnboardingOperation = makeMutation<UpdateDojoIslandsOnboardingParams, void>({
  name: "updateDojoIslandsOnboarding",
  fn: async ({ classId, stepId, status }) => {
    const body: UpdateDojoIslandsOnboardingbody = {
      [stepId]: { status },
    };
    await callApi({
      method: "PATCH",
      path: `/api/dojoClass/${classId}/dojoIslandsOnboarding`,
      body,
    });
  },
  onMutate: (params) => {
    useDojoIslandsOnboardingFetcher.setQueriesData(
      (draft) => {
        if (!draft) {
          return;
        }
        draft[params.stepId] = { status: params.status };
      },
      { classId: params.classId },
    );
  },
});

export const useLocalizedDojoIslandsPdfLink = (fileUrl: string, supportedLocalesMap: Record<string, string[]>) => {
  const teacher = useFetchedTeacher();
  const teacherLocale = teacher.locale?.toLowerCase();
  const targetLocale =
    teacherLocale &&
    Object.keys(supportedLocalesMap).find((locale) => {
      const supportedLocales = supportedLocalesMap[locale];
      if (supportedLocales?.some((locale) => locale.toLowerCase() === teacherLocale)) {
        return locale;
      }
    });

  if (targetLocale) {
    return fileUrl.replace(".pdf", `-${targetLocale}.pdf`);
  }

  return fileUrl;
};

const useDojoIslandsContentStatusFetcher = makeMemberQuery({
  path: `/api/teacher/{teacherId}/dojoIslandsContentStatus`,
  fetcherName: "dojoIslandsContentStatus",
  queryParams: ["classId"],
});

export type UpdateDojoIslandsContentStatusParams = APIRequestBody<
  "/api/teacher/{teacherId}/dojoIslandsContentStatus",
  "post"
> & { teacherId: string; classId: string };

export const useDojoIslandsContentStatusOperation = makeMutation<UpdateDojoIslandsContentStatusParams, void>({
  name: "updateDojoIslandsContentStatus",
  fn: async ({ teacherId, seenIds, dismissedIds }) => {
    await callApi({
      method: "POST",
      path: `/api/teacher/${teacherId}/dojoIslandsContentStatus`,
      body: { seenIds, dismissedIds },
    });
  },
  onMutate: (params) => {
    useDojoIslandsContentStatusFetcher.setQueriesData(
      (draft) => {
        if (!draft) {
          return;
        }
        if (params.classId) {
          draft.seenIds.push(...(params.seenIds?.map((item) => item.contentId) || []));
          draft.dismissedIds.push(...(params.dismissedIds?.map((item) => item.contentId) || []));
        }
      },
      { teacherId: params.teacherId, classId: params.classId },
    );
  },
  onSuccess: (_data, params) => {
    const isGlobalUpdate =
      params.seenIds?.some((item) => !item.classId) || params.dismissedIds?.some((item) => !item.classId);
    if (isGlobalUpdate) {
      useDojoIslandsContentStatusFetcher.invalidateQueries();
    }
  },
});

export const DOJO_ISLANDS_BADGE_IDS = {
  /** The purple-colored [NEW] tag next to the DojoIslands header tab label (deprecated) */
  HEADER_TAB_NEW_TAG: "66df561eb20199e7754daf7d",
  /** Red badge for Spooky Season 2024 Week 1 */
  SPOOKY_BADGE_10_01_2024: "66f31b949bb61fd2372d408d",
  /** Number Shores Badge */
  NUMBER_SHORES_BADGE_11_2024: "672d471bbe72f9809396e001",
};

export const useDojoIslandsTabSortedSections = <T extends { sectionId: string }>(
  teacherId: string,
  classId: string,
  allSections: T[],
): Array<T & { dismissed?: boolean }> => {
  const { data } = useDojoIslandsContentStatusFetcher({ teacherId, classId });

  return useMemo(() => {
    if (!data) return [];

    const sections: T[] = [];
    const dismissedSections: T[] = [];

    allSections.forEach((section) => {
      if (data.dismissedIds.includes(section.sectionId)) {
        dismissedSections.push({ ...section, dismissed: true });
      } else {
        sections.push(section);
      }
    });

    return [...sections, ...dismissedSections];
  }, [allSections, data]);
};

type BadgeType = "red" | "new";

type BadgeData = Array<{
  sectionId: string;
  global: boolean;
  expiryDate: Date;
}>;

const useGetElegibleRedBadges = (classId: string) => {
  const sectionsToBadge: BadgeData = [];
  // The global flag indicates whether the badge should be cleared for *all* classrooms that teacher can see (true)
  // OR only for the specific classroom (false)
  const showSpookyTheme = useMonsterverseSeasonalContent(classId)?.spookySeason?.showToggle;
  if (showSpookyTheme) {
    sectionsToBadge.push({
      sectionId: DOJO_ISLANDS_BADGE_IDS.SPOOKY_BADGE_10_01_2024,
      global: false,
      expiryDate: new Date("2024-11-01"),
    });
  }
  return sectionsToBadge;
};

const useGetElegibleNewBadges = (classId: string) => {
  const sectionsToBadge: BadgeData = [];
  const numberShoresVariant = useFetchedClassroomFeatureSwitchVariant(
    classId,
    CLASSROOM_FEATURE_SWITCHES.MV_NUMBER_SHORES,
  );
  if (showNumberShoresBanner(numberShoresVariant)) {
    sectionsToBadge.push({
      sectionId: DOJO_ISLANDS_BADGE_IDS.NUMBER_SHORES_BADGE_11_2024,
      global: true,
      expiryDate: new Date("2024-12-15"),
    });
  }
  return sectionsToBadge;
};

// DojoIslands currently only shows new badge. TBD if we want to bring back counts
export const useGetBadgeCount = (teacherId: string, classId: string, badgeType: BadgeType): number => {
  const { data } = useDojoIslandsContentStatusFetcher({ teacherId, classId });
  const redBadges = useGetElegibleRedBadges(classId);
  const newBadges = useGetElegibleNewBadges(classId);

  if (badgeType !== "red" && badgeType !== "new") {
    return 0;
  }
  if (!data) {
    return 0;
  }

  const sectionsToBadge = badgeType === "red" ? redBadges : newBadges;
  let badgeCount = 0;
  const currentDate = new Date();

  for (const { sectionId, expiryDate } of sectionsToBadge) {
    const shouldBadge =
      !data.seenIds.includes(sectionId) && !data.dismissedIds.includes(sectionId) && expiryDate > currentDate;
    if (shouldBadge) {
      badgeCount++;
    }
  }
  return badgeCount;
};

export const useMarkDojoIslandsContentsSeenOnFirstRender = (teacherId: string, classId: string) => {
  const { mutate: updateSeenReceipts } = useDojoIslandsContentStatusOperation();
  const redBadges = useGetElegibleRedBadges(classId);
  const newBadges = useGetElegibleNewBadges(classId);
  const badgesToClear = redBadges.length > 0 ? redBadges : newBadges;

  useOnFirstRender(() => {
    return (
      badgesToClear.length > 0 &&
      updateSeenReceipts({
        teacherId,
        classId,
        seenIds: badgesToClear.map(({ sectionId, global }) => ({
          classId: global ? undefined : classId,
          contentId: sectionId,
        })),
        dismissedIds: [],
      })
    );
  });
};

type LocaleValue = (typeof locales)[number]["value"];
const SUPPORTED_TRANSLATION_LOCALES: LocaleValue[] = [
  "en-US",
  "en-GB",
  "id-ID",
  "ca-ES",
  "de-DE",
  "es-MX",
  "es-ES",
  "fr-CA",
  "fr-FR",
  "pl-PL",
  "pt-BR",
  "pt-PT",
  "tr-TR",
  "vi-VN",
  "ru-RU",
  "uk-UA",
  "hi-IN",
  "ko-KR",
  "zh-CN",
  "ja-JP",
  "fil-PH",
  "th-TH",
] as const;

export const useDojoIslandsFTUEData = (locale: string): { posterUrl: string; videoUrl: string; captionSrc: string } => {
  return useMemo(() => {
    const supportedLocale = SUPPORTED_TRANSLATION_LOCALES.find((l) => l === locale) ?? "en-US";
    const localeKey = supportedLocale.replace(/-/g, "").toLowerCase();
    return {
      posterUrl: "https://static.classdojo.com/DojoIslands/FTUE/FTUE2_540p_Poster_v2.webp",
      videoUrl: "https://static.classdojo.com/DojoIslands/FTUE/FTUE2_540p_v3.mp4",
      captionSrc: `https://static.classdojo.com/DojoIslands/FTUE/dojo.dojo_island_FTUE2_subs_${localeKey}.vtt`,
    };
  }, [locale]);
};

export { getMonsterWorldUrl } from "./utils/monsterWorldUrl";
